

/* .formContainer .formItem{
    margin-right: 30px;
} */

/*.formContainer .formItem input {*/
/*	border: 1.5px solid grey;*/
/*	border-radius: 40px;*/
/*	height: 55px;*/
/*	width: 400px;*/
/*	background-color: #131d25;*/
/*	padding-left: 15px;*/
/*	color: #fff;*/
/*}*/

.formContainer .formItem h6 {
	padding: 0 0 10px 15px;
	font-size: 14px;
    color: #fff;
}

/*.formContainer .formItem select {*/
/*	width: 400px;*/
/*	height: 55px;*/
/*	background-color: #131d25;*/
/*	border-radius: 40px;*/
/*	border: 1.5px solid grey;*/
/*	padding-left: 15px;*/
/*	color: grey;*/
/*}*/

.formContainer .fileChoose input {
	padding-top: 13px;
	color: grey;
}

.formContainer .fileChoose input::file-selector-button {
	display: none;
}

.formContainer .formItemColorPicker {
	display: flex;
	align-items: start;
}

.formContainer .formItemColorPicker input {
	height: 40px;
	width: 40px;
	/*background-color: #131d25;*/
	color: #fff;
	margin-left: 0;
}

.buttonContainer {
	justify-content: space-between;
	display: flex;
	align-items: center;
}

.buttonContainer button {
	height: 50px;
	border-radius: 15px;
	background-color: var(--yellow-color);
	color: grey;
	display: flex;
	align-items: center;
	color: #000;
}

.buttonContainer h1 {
	width: 90%;
}

@keyframes spinner {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

.loading-spinner {
	width: 25px;
	min-width: 25px;
	height: 25px;
	border: 3px solid #f3f3f3;
	border-top: 3px solid #383636;
	border-radius: 50%;
	animation: spinner 1.5s linear infinite;
}

.loading-btn {
	width: 20px;
	min-width: 20px;
	height: 20px;
	border: 3px solid #383636;
	border-top: 3px solid #fff;
	border-radius: 50%;
	animation: spinner 1.5s linear infinite;
}

.loading-overlay {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0,0,0, .5);
	z-index: 1;
	border-radius: 2.5rem;
}

.loading-image {
	position: absolute;
	left: 42%;
	top: 42%;
	transform: translate(-42%, -42%);
	width: 20px;
	min-width: 20px;
	height: 20px;
	border: 3px solid #fff;
	border-top: 3px solid #383636;
	border-radius: 50%;
	animation: spinner 1.5s linear infinite;
}

.room-nfts-row { margin: 30px 10px!important; padding: 0 20px 20px }

.add-new-btn { width: 20%; margin: 20px auto; }