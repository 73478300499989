.title {
    cursor: pointer;
}

.content {
    max-height: 0;
    overflow: hidden;
    transition: all .5s cubic-bezier(0,1,0,1);
}

.content.show {
    height: auto;
    max-height: 9999px;
    transition: all .5s cubic-bezier(1,0,1,0);
}

.list-circle {
    list-style-type: circle;
}