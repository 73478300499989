.avatar_box {
    border: 2px solid #5B5A99;
    border-radius: 30px;
    -webkit-border-radius: 30px;
    overflow: hidden;
    background-repeat: no-repeat;
    background-size: cover;
}
.avatar_img {
    transform: scale(2) translateY(17%);
}
.avatar_btn {
    min-width: 167px;
    height: 42px!important;
}
.avatar_upload {
    height: 100%;
    min-height: 181px;
    background-color: #1B183F;
    border-radius: 10px;
    -webkit-border-radius: 10px;
}
.upload-icon {
    border: 3px solid #00ffff;
    border-radius: 30px;
    -webkit-border-radius: 30px;
    padding: 5px;
    width: 36px;
    min-width: 36px;
    height: 36px;
    object-fit: cover;
    color: #00ffff;
    font-size: 20px;
}
.upload-icon span {
    font-size: 16px;
    line-height: 19px;
}
.avatar_upload:hover span {
    color: #00ffff!important;
}
.avatar_actions {
    width: 90%;
    position: absolute;
    bottom: -50%;
    left: 50%;
    right: 50%;
    transform: translateX(-50%);
}
.avatar_box:hover .avatar_actions {
    transition: all .2s ease-in-out;
    position: absolute;
    bottom: 10px;
}
.package_btn {
    width: 139px;
    height: 35px!important;
}
.referral-stats-icon {
    width: 50px;
    height: 50px;
    min-width: 50px;
    object-fit: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
    -webkit-border-radius: 30px;
}
.referral-table .ant-pagination-options {
    display: none;
}
.referral-table .ant-pagination-item-ellipsis {
    font-size: 12px;
    color: #1890ff!important;
}
.referral-table .ant-table,
.referral-table .ant-table-tbody > tr > td {
    background: #1C1A42;
}
.referral-table .ant-table-thead > tr > th,
.referral-table .ant-table-tbody > tr > td {
    border-bottom: 1px solid #2c2b4c;
    padding: 10px 16px;
}
.referral-table .ant-table-tbody > tr.ant-table-placeholder:hover > td {
    background: #1c1a42;
}
.referral-table .ant-empty-description {
    color: #fff;
}
.referral-table .ant-table-thead > tr > th {
    background: #19183e;
    color: #50E3C2;
    font-size: 15px;
    font-weight: 300;
}
.referral-table .ant-table-tbody > tr > td {
    color: #fff;
    font-weight: 300;
    font-size: 1rem;
}
.referral-table .ant-table-cell-scrollbar:not([rowspan]) {
    box-shadow: none;
}
.referral-table .ant-table-tbody > tr.ant-table-row:hover > td,
.referral-table .ant-table-tbody > tr > td.ant-table-cell-row-hover {
    background: #2b2850;
}
.referral-table .ant-pagination-item,
.referral-table .ant-pagination-prev .ant-pagination-item-link,
.referral-table .ant-pagination-next .ant-pagination-item-link {
    background: #1C1A42;
    border: none;
    color: #00ccff;
}
.referral-table .ant-pagination-item a {
    color: #00ccff;
}
.referral-table .ant-pagination-disabled .ant-pagination-item-link,
.referral-table .ant-pagination-disabled:hover .ant-pagination-item-link {
    background: #343261;
    opacity: .5;
    border: none;
}
.referral-table .ant-pagination-disabled .ant-pagination-item-link svg,
.referral-table .ant-pagination-disabled:hover .ant-pagination-item-link svg {
    fill: #fff;
}
.referral-table .ant-pagination-item-active {
    background: #00ccff;
}
.referral-table .ant-pagination-item-active a,
.referral-table .ant-pagination-item-active:hover a {
    color: #1C1A42;
}
.referral-table .ant-table-filter-trigger:hover {
    color: #50E3C2;
}
.ant-table-filter-dropdown {
    background-color: #27244b!important;
}
.filter-input {
    background-color: #5b5a99!important;
    border: none!important;
    margin-bottom: 8px!important;
    display: block!important;
    color: #fff!important;
}
.referral-table .avatar_box {
    /*border: 1px solid #5B5A99;*/
    /*border-radius: 10px;*/
    /*-webkit-border-radius: 10px;*/
    border: none;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    overflow: hidden;
}
.status-table .ant-table-cell {
    vertical-align: baseline!important;
}
.status-table .ant-table,
.status-table .ant-table-tbody > tr > td {
    background: #1C1A42;
}
.status-table .ant-table-tbody > tr > td {
    color: #fff;
    padding: 20px 18px;
}
.status-table .ant-table-tbody > tr:nth-child(6) > td,
.status-table .ant-table-tbody > tr:nth-child(10) > td,
.status-table .ant-table-tbody > tr:nth-child(14) > td {
    border-top: 1px solid #5b5a99;
}
.status-table .ant-table-thead > tr > th,
.status-table .ant-table-tbody > tr > td {
    border: none;
}
.status-table .ant-table-thead > tr > th {
    background: #1C1A42;
    padding: 20px 18px 0;
}
.status-table .ant-table-tbody > tr.ant-table-row:hover > td,
.status-table .ant-table-tbody > tr > td.ant-table-cell-row-hover {
    background: none;
}
.status-table .ant-table-thead > tr > th.active,
.status-table .ant-table-tbody > tr > td.active {
    background: #2D2B5A!important;
}
.status_box {
    border: none!important;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    /*border-radius: 18px;*/
    /*-webkit-border-radius: 18px;*/
    width: 48px;
    height: 48px;
    min-width: 48px;
    margin: 16px auto;
}
.status_empty {
    border: 2px dashed #5b5a99;
}
.status_badge {
    background: #5b5a99;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    padding: 6px;
    display: inline-block;
}
.referral-table .game_avatar {
    border: none;
    border-radius: 0;
    overflow: unset;
}
.referral-table .game_avatar img {
    transform: none;
}
.claims-thumb {
    padding: 25px 35px;
    background-color: #1C1A42;
    border-radius: 15px;
    -webkit-border-radius: 15px;
    row-gap: 10px;
}
.claims-thumb_icon {
    height: 64px;
}
.claims-thumb_amount {
    font-size: 22px;
    line-height: 26px;
}
.claims-thumb_label {
    font-size: 15px;
    line-height: 18px;
}
.claims-thumb_ctg {
    font-size: 14px;
    line-height: 16px;
    color: #9594CA;
}
.claims-thumb {
    padding: 26px 10px;
}
.vert_menu a:hover {
    opacity: 1;
}
