.grid-container .grid-item .nft-room {
	border-radius: 24px;
	overflow: hidden;
}

.grid-container .grid-item .nft-room .nft-room-head {
	max-height: 400px;
	overflow: hidden;
	width: 360px;
}

.grid-container .grid-item .nft-room .nft-room-head img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.grid-container .grid-item .nft-room .nft-room-bottom {
	background: #1c1a42;
}
.btn-pink {
    background-color: #ff409a !important;
    color: #fff !important;
}

.btn-pink:hover {
    background-color: #ba276c !important;
    color: rgb(201, 201, 201) !important;
}

.rounded {
    border-radius: 0.25rem !important;
}
  
.rounded-0 {
    border-radius: 0 !important;
}

.rounded-1 {
    border-radius: 0.2rem !important;
}

.rounded-2 {
    border-radius: 0.25rem !important;
}

.rounded-3 {
    border-radius: 0.3rem !important;
}

.rounded-circle {
    border-radius: 50% !important;
}

.rounded-pill {
    border-radius: 50rem !important;
}
.btn {
    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    /*-webkit-user-select: none;*/
    /*-moz-user-select: none;*/
    /*user-select: none;*/
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
    .btn {
        transition: none;
    }
}
.btn:hover {
    color: #212529;
}
.font-12 {
    font-size: 12px !important;
}
  
.font-14 {
    font-size: 14px !important;
}
  
.font-16 {
    font-size: 16px;
}
  
.btn-orange {
    background-color: #da9e6b;
    border-color: #da9e6b;
}
  
.btn-orange:hover {
    background-color: #a47348;
    border-color: #a47348;
}
  
.base-color-orange {
    color: #da9e6b;
}
  
.base-color-gray {
    color: #353945;
}
  
.base-color-pink {
    color: #ff409a;
}
  
.base-color-gray-500 {
    color: #767e90;
}
  
.base-color-light-gray {
    color: #f4f5f6;
}
  
.base-selected-border-color {
    border-color: #58c27d;
}
  
.base-selected-background-color {
    border-color: #f2faf5;
}
  
.base-border-radius {
    border-radius: 24px;
}
.float-start {
    float: left !important;
}

.float-end {
    float: right !important;
}

.float-none {
    float: none !important;
}
  