.menu {
    border-bottom: solid 1px #f5e2e2;
    overflow: auto;
    padding: 0px 19px;
    background-color: #fff;
}
.w-354 {
    width: 354px;
}
.avatar {
    width: 32px;
    min-width: 32px;
    height: 32px;
    object-fit: cover;
    border-radius: 30px;
}
.drop-status {
    font-weight: bold!important;
    display: inline-block!important;
    padding: 0!important;
}
.get-link {
    font-weight: bold!important;
    font-size: 12px!important;
    line-height: 14px;
    background: #5B5A99;
    border-radius: 14px;
    -webkit-border-radius: 14px;
    padding: 5px 7px!important;
    display: inline-block!important;
}
.sidebar-title {
    border-bottom: 1px solid #2b2850;
    padding-bottom: 10px;
}
.main-logo {
    width: 152px;
    float: left !important;
}

.main-logo a {
    padding: 18px 21px;
    font-size: 20px;
    display: inline-block;
}

.menu-container .ant-menu-item {
    padding: 0px 5px;
}

.menu-container .ant-menu-submenu-title {
    padding: 10px 20px;
}

.menu-container .ant-menu-item a,
.menu-container .ant-menu-submenu-title a {
    padding: 10px 15px;
}

.menu-container .ant-menu-horizontal {
    border-bottom: none;
}

.menu-container .left-menu {
    float: left;
}

.menu-container .right-menu {
    float: right;
}

.small-screen-btn {
    padding: 7px;
    display: none;
}
.menu-drawer .ant-drawer-content,
.menu-drawer .ant-drawer-header{
    background-color: #1c1a42;
}
.menu-drawer .ant-drawer-header {
    border-bottom-color: #343261;

}
.menu-drawer .anticon-close svg {
    fill: #fff;
}
.menu-active {
    top: 92px;
    border: 1px solid #5B5A99;
    border-width: 1px 0;
}
.menu-active a {
    border-bottom: 2px solid transparent;
    padding: 23px 0;
}
.menu-active a:hover {
    color: #fff!important;
}
.menu-active a.active,
.menu-active button.active {
    color: #fff!important;
    border-bottom-color: #FF409A;
}
.drop-btn {
    height: 29px!important;
    min-width: 116px;
}
.nav-status:hover span {
    color: #fff!important;
}
.nav-right a,
.nav-right a i {
    color: #b1afcd;
}
.nav-right a:hover,
.nav-right a:hover i {
    color: #fff;
}

@media (max-width: 425px) {
    .guide-hide {
        display: none;
    }
    .guide-show {
        display: block;
    }
    .xs-w-100 {
        width: 100%;
    }
}
@media (max-width: 768px) {
    .fag-head {
        padding: 12px 14px;
    }
    .fag-head > h2 {
        font-size: 1rem;
    }
    .fag-head > img {
        width: 18px;
    }
    .register-form>div {
        width: 100%;
    }
    .xs-mt-0 {
        margin-top: 0;
    }
    .xs-p-2 {
        padding: 2rem;
    }
    .xs-p-0 {
        padding: 0!important;
    }
    .account-textarea {
        width: 100%;
    }
    .switch-input {
        flex-direction: column;
        row-gap: 1em;
    }
    .settings-content {
        margin-left: 0;
        width: 100%!important;
        max-width: unset;
        padding: 2em;
    }
    .settings-content input {
        width: 100%;
    }
    .sm-mt-0 {
        margin-top: 0;
    }
    .sm-justify-center {
        justify-content: center;
    }
    .ant-row {
        margin-left: 0!important;
        margin-right: 0!important;
    }
    .sm-px-0 {
        padding-left: 0;
        padding-right: 0;
    }
    .sm-ml-0 {
        margin-left: 0!important;
    }
    /*.pt-162 {*/
    /*    padding-top: 125px;*/
    /*}*/
    .visible-md {
        display: none;
    }
    .guide-show {
        display: none;
    }
    .xs-col-full .ant-col {
        flex: 0 0 100%;
        max-width: 100%;
    }
    .sm-col-px-0 .ant-col {
        padding-left: 0!important;
        padding-right: 0!important;
    }
}
@media (max-width: 992px) {
    .account-form {
        flex-direction: column;
    }
    .account-form > div {
        width: 100%;
        margin-bottom: 2em;
    }
    .account-social >div {
        flex: 0 0 100%;
        max-width: 100%;
    }
    .small-screen-btn {
        display: block;
    }
    .visible-lg {
        display: none;
    }
    .md-mt-0 {
        margin-top: 0;
    }
    .input-style {
        width: 100%;
    }
}
@media (min-width: 993px) {
    .hidden-md {
        display: none;
    }
}
@media (min-width: 769px) {
    .visible-xs {
        display: none;
    }
}

@media (min-width: 767px) and (max-width: 992px){
    .guide-hide {
        display: none;
    }
    .guide-show {
        display: block;
    }
}