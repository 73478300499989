:root{
    --small: 576px;
    --medium: 768px;
    --large: 992px;
    --xlarge: 1200px;
}


/*Icon Font*/
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800&display=swap');

@font-face {
    font-family: 'metaicon-v1';
    src:  local("metaicon-v1"), url('./fonts/metaicon-vv1.eot?tnhj0k');
    src:  local("metaicon-v1"), url('./fonts/metaicon-vv1.eot?tnhj0k#iefix') format('embedded-opentype'),
    local("metaicon-v1"), url('./fonts/metaicon-vv1.ttf?tnhj0k') format('truetype'),
    local("metaicon-v1"), url('./fonts/metaicon-vv1.woff?tnhj0k') format('woff'),
    local("metaicon-v1"), url('./fonts/metaicon-vv1.svg?tnhj0k#metaicon-v1') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}


i[class*="icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'metaicon-v1' !important;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-gift:before {
    content: "\e92b";
}
.icon-whatsapp:before {
    content: "\e918";
}
.icon-caret-down:before {
    content: "\e919";
}
.icon-clock:before {
    content: "\e91a";
}
.icon-upload:before {
    content: "\e91b";
}
.icon-charity:before {
    content: "\e91c";
}
.icon-dating:before {
    content: "\e91d";
}
.icon-exhibition:before {
    content: "\e91e";
}
.icon-cinema:before {
    content: "\e91f";
}
.icon-education:before {
    content: "\e920";
}
.icon-auction:before {
    content: "\e921";
}
.icon-battle:before {
    content: "\e922";
}
.icon-presentation:before {
    content: "\e923";
}
.icon-networking:before {
    content: "\e924";
}
.icon-cake:before {
    content: "\e925";
}
.icon-live:before {
    content: "\e926";
}
.icon-competition:before {
    content: "\e927";
}
.icon-giveaway:before {
    content: "\e928";
}
.icon-category:before {
    content: "\e929";
}
.icon-share:before {
    content: "\e92a";
}
.icon-user-add:before {
    content: "\e917";
}
.icon-user-plus:before {
    content: "\e916";
}
.icon-user:before {
    content: "\e900";
}
.icon-star:before {
    content: "\e901";
}
.icon-settings:before {
    content: "\e902";
}
.icon-people:before {
    content: "\e903";
}
.icon-pin:before {
    content: "\e904";
}
.icon-plus:before {
    content: "\e905";
}
.icon-pro:before {
    content: "\e906";
}
.icon-remove:before {
    content: "\e907";
}
.icon-right-circle:before {
    content: "\e908";
}
.icon-search:before {
    content: "\e909";
}
.icon-metahut:before {
    content: "\e90a";
}
.icon-discovery:before {
    content: "\e90b";
}
.icon-map-marker:before {
    content: "\e90c";
}
.icon-eye:before {
    content: "\e90d";
}
.icon-info:before {
    content: "\e90e";
}
.icon-copy:before {
    content: "\e90f";
}
.icon-check:before {
    content: "\e910";
}
.icon-back:before {
    content: "\e911";
}
.icon-discord:before {
    content: "\e912";
}
.icon-zoom-in:before {
    content: "\e913";
}
.icon-zoom-out:before {
    content: "\e914";
}
.icon-user-angle-right:before {
    content: "\e915";
}
.icon-globe:before {
    content: "\e9ca";
}
.icon-youtube:before {
    content: "\ea9d";
}
.icon-close:before {
    content: "\f00d";
}
.icon-linkedin:before {
    content: "\f08c";
}
.icon-twitter:before {
    content: "\f099";
}
.icon-burger:before {
    content: "\f0c9";
}
.icon-angle-left:before {
    content: "\f104";
}
.icon-angle-right:before {
    content: "\f105";
}
.icon-instagram:before {
    content: "\f16d";
}
.icon-reddit:before {
    content: "\f1a1";
}
.icon-facebook:before {
    content: "\f230";
}
.icon-telegram:before {
    content: "\f2c6";
}

body {
    background-color: #27244b;
}

.font-sans {
    font-family: Roboto,sans-serif;
}
.font-mont {
    font-family: "Montserrat";
}
.font-light {
    font-weight: 300;
}
.font-extralight {
    font-weight: 200!important;
}
.text-center {
    text-align: center;
}
.text-left {
    text-align: left;
}
.text-right {
    text-align: right;
}
.text-xxs {
    font-size: 10px;
    line-height: 12px;
}
.text-xs_ {
    font-size: 11px;
    line-height: 13px;
}
.text-xs {
    font-size: .75rem;
    line-height: 1rem;
}
.text-s {
    font-size: 13px;
    line-height: 18px;
}
.text-sm {
    font-size: 0.875rem;
    line-height: 1.25rem;
}
.text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
}
.text-base {
    font-size: 1rem !important;
    line-height: 1.5rem !important;
}
.text-xl {
    font-size: 1.25rem !important;
    line-height: 1.75rem !important;
}
.text-2xl {
    font-size: 1.5rem !important;
    line-height: 2rem !important;
}
.text-3xl {
    font-size: 2rem !important;
    line-height: 2.5rem !important;
}
.text-5xl {
    font-size: 3rem !important;
    line-height: 1 !important;
}
.text-white {
    color: #fff !important;
}
.text-black {
    color: #000;
}
.text-lime-500 {
    color: #00FF38;
}
.text-slate-400 {
    color: #9594ca;
}
.text-red {
    color: #EE5757;
}
.text-rose-500 {
    color: #E3507A;
}
.text-cyan-500 {
    color: #50e3c2;
}
.text-cyan-400 {
    color: #00ffff;
}
.text-teal-900 {
    color: #134E4A;
}
.text-gray-700 {
    color: #374151;
}
.text-gray-300 {
    color: #9796cf !important;
}
.text-gray-200 {
    color: #BCBAF9 !important;
}
.text-indigo-900 {
    color: #1c1a42 !important;
}
.text-indigo-800 {
    color: #2B2850;
}
.text-indigo-700 {
    color: #5B5A99!important;
}
.text-gray-light {
    color: #b1afcd!important;
}
.object-cover {
    object-fit: cover;
}
.max-h-fit {
    max-height: fit-content;
}
.min-h-screen {
    min-height: 100vh;
}
.h-dvh {
    height: 100dvh;
}
.min-w-24 {
    min-width: 6rem;
}
.min-w-14 {
    min-width: 14px;
}
.min-w-full {
    min-width: 100%;
}
.min-h-land {
    min-height: 233px;
}
.min-h-content {
    min-height: 136px;
}
.tooltip-tr {
    padding-bottom: 10px;
}
.tooltip-tr.ant-tooltip-placement-topRight .ant-tooltip-arrow {
    right: 0;
} 
.addon-after {
    position: relative;
}
.addon-after span {
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translateY(-50%);
}
.swap-btn {
    border: none;
    display: flex;
    align-items: center;
    padding: 8px;
    border-radius: 50%;
    width: 30px;
    min-width: 30px;
    height: 30px;
}
.block {
    display: block;
}
.inline-block {
    display: inline-block;
}
.inline {
    display: inline;
}
.leading-0 {
    line-height: 0;
}
.btn-disable {
    color: #fff;
    background-color: #1c1a42;
    cursor: not-allowed;
    opacity: .5;
}
.input {
    width: 354px;
    height: 46px;
    padding: 10px;
    font-size: 15px;
}
.input-security {
    width: 300px;
    height: 40px;
}
.input-search {
    width: 307px;
    height: 44px;
    padding: 0;
    background-color: #3F3C77;
    border: 2px solid #9796CF;
    border-radius: 10px;
    -webkit-border-radius: 10px;
}
.input-search .ant-select-selector {
    width: 100%!important;
    height: 100%!important;
    background-color: #3F3C77!important;
    border-radius: 10px!important;
    -webkit-border-radius: 10px!important;
    border: none!important;
    align-items: center;
}
.input-search .ant-select-selector input {
    color: #fff!important;
    height: 100%!important;
}
.input-search .ant-select-selection-placeholder {
    display: flex;
    align-items: center;
}
.input-search .ant-select-clear {
    background: transparent;
    color: #fff;
}
.input-search .ant-select-selection-item {
    background: #1B183F;
    border-color: #1c1a42;
    color: #fff;
    border-radius: 6px;
    flex: unset;
    padding: 0 5px!important;
}
.input-search .ant-select-selection-item .anticon-close svg {
    fill: rgba(255, 255, 255, .5);
}
.input-search .ant-input,
.input-search-table .ant-input {
    background-color: #3F3C77;
    color: #9796CF;
    border-radius: 10px;
}
.input-search-table {
    border: 1px solid #9796CF;
    background-color: #3F3C77;
    height: 32px;
    width: 100%;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    font-size: 13px;
    font-weight: 300;
}
.input:focus-visible {
    outline: 1px solid transparent;
}
.input::placeholder {
    color: #fff!important;
}
.select-style {
    appearance: none;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 1rem center;
    background-size: 2em;
}
.input-style {
    outline: none;
    border: none;
    width: 80%;
}
.input-style::placeholder {
    color: #fff!important;
}
.input-style:focus-visible {
    border: 1px solid #00FFFF!important;
}
.input-prefix input {
    background-color: #5B5A99;
    color: white;
}
.validation-border {
    border: 1px solid red !important;
}
.menu-y {
    width: 1px;
    height: 19px;
    background-color: #5b5a99;
}
.menu-nav > a {
    font-size: 18px;
    line-height: 22px;
    font-weight: 500;
}
.icon-comm a {
    color: white;
    line-height: 0;
    font-size: 18px;
}
.upload-btn-wrapper {
    position: relative;
    overflow: hidden;
    display: inline-block;
    cursor: pointer;
}
.upload-btn-wrapper input[type=file] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    width: 121px;
    height: 32px;
    border-radius: 1rem;
    cursor: pointer;
    z-index: 1;
}
/*.sidebar-bg:after {*/
/*    content: "";*/
/*    position: absolute;*/
/*    top: 0;*/
/*    left: -100%;*/
/*    width: 100%;*/
/*    height: 100%;*/
/*    background-color: #1C1A42;*/
/*}*/
.settings-layout {
    position: relative;
    background-color: #1C1A42;
    min-height: 657px;
}
.settings-layout:after {
    content: "";
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background-color: #1C1A42;
}
.settings-content {
    max-width: calc(100% - 260px);
    margin-left: auto;
    background-color: #27244b;
    padding-bottom: 80px;
}
.loader {
    border: 4px solid #50b5e2;
    border-radius: 50%;
    border-top: 4px solid #af38c7;
    width: 30px;
    height: 30px;
    text-indent: -9999em;
    animation: spin 2s linear infinite;
}
.icon-info {
    transform: rotate(180deg);
}
.p-0 {
    padding: 0;
}
.p-5px {
    padding: 5px;
}
.p-4 {
    padding: 1rem;
}
.p-6 {
    padding: 1.5rem;
}
.p-2 {
    padding: .5rem;
}
.pt-2 {
    padding-top: .5rem;
}
.pt-4 {
    padding-top: 1rem;
}
.pt-8 {
    padding-top: 2rem;
}
.pb-0 {
    padding-bottom: 0;
}
.pb-2 {
    padding-bottom: .5rem;
}
.pb-4 {
    padding-bottom: 1rem;
}
.pb-5 {
    padding-bottom: 1.25rem;
}
.pb-8 {
    padding-bottom: 2rem;
}
.pb-25 {
    padding-bottom: 5.25rem;
}
.pb-24 {
    padding-bottom: 6rem;
}
.py-12 {
    padding-top: 3rem;
    padding-bottom: 3rem;
}
.py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem;
}
.py-7 {
    padding-top: 1.75rem;
    padding-bottom: 1.75rem;
}
.py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
}
.py-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
}
.py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
}
.py-3 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
}
.py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}
.py-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
}
.py-0 {
    padding-top: 0;
    padding-bottom: 0;
}
.px-0 {
    padding-left: 0;
    padding-right: 0;
}
.px-2 {
    padding-left: .5rem;
    padding-right: .5rem;
}
.px-3 {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
}
.px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
}
.px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
}
.px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}
.px-7 {
    padding-left: 1.75rem;
    padding-right: 1.75rem;
}
.px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
}
.px-9 {
    padding-left: 2.25rem;
    padding-right: 2.25rem;
}
.px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
}
.px-12 {
    padding-left: 3rem;
    padding-right: 3rem;
}
.px-14 {
    padding-left: 3.5rem;
    padding-right: 3.5rem;
}
.px-24 {
    padding-left: 6rem;
    padding-right: 6rem;
}
.pr-0 {
    padding-right: 0;
}
.pr-3 {
    padding-right: .75rem;
}
.pr-4 {
    padding-right: 1rem;
}
.pr-5 {
    padding-right: 1.25rem;
}
.pr-10 {
    padding-right: 2.5rem;
}
.pr-14 {
    padding-right: 3.5rem;
}
.pl-0 {
    padding-left: 0;
}
.pl-3 {
    padding-left: 0.75rem;
}
.pl-4 {
    padding-left: 1rem;
}
.pl-5 {
    padding-left: 1.25rem;
}
.pl-8 {
    padding-left: 2rem;
}
.pl-10 {
    padding-left: 2.5rem;
}
.pl-14 {
    padding-left: 3.5rem;
}
.ml-0 {
    margin-left: 0!important;
}
.ml-1 {
    margin-left: 0.25rem;
}
.ml-2 {
    margin-left: 0.5rem;
}
.ml-3 {
    margin-left: 0.75rem;
}
.ml-4 {
    margin-left: 1rem;
}
.mr-1 {
    margin-right: 0.25rem;
}
.mr-4 {
    margin-right: 1rem;
}
.mb-0 {
    margin-bottom: 0;
}
.mb-2 {
    margin-bottom: .5rem;
}
.mb-3 {
    margin-bottom: .75rem;
}
.mb-4 {
    margin-bottom: 1rem;
}
.mb-5 {
    margin-bottom: 1.25rem;
}
.mb-6 {
    margin-bottom: 1.5rem;
}
.mb-7 {
    margin-bottom: 1.75rem;
}
.mb-8 {
    margin-bottom: 2rem;
}
.mb-9 {
    margin-bottom: 2.25rem;
}
.mb-10 {
    margin-bottom: 2.5rem;
}
.mb-11 {
    margin-bottom: 2.75rem;
}
.mb-12 {
    margin-bottom: 3rem;
}
.mb-15 {
    margin-bottom: 3.75rem;
}
.mb-16 {
    margin-bottom: 4rem;
}
.mb-17 {
    margin-bottom: 4.25rem;
}
.mb-19 {
    margin-bottom: 4.75rem;
}
.mb-20 {
    margin-bottom: 5rem;
}
.bg-transparent {
    background-color: transparent!important;
}
.bg-pink-400 {
    background-color: #FF409A;
}
.bg-pink-300 {
    background-color: #E3507A;
}
.bg-white {
    background-color: #fff;
}
.bg-white-80 {
    background-color: rgba(255,255,255, .8);
}
.bg-red-200 {
    background-color: #FCB8D8;
}
.bg-green-500 {
    background-color: #12A80F;
}
.bg-indigo-400 {
    background-color: #211F45;
}
.bg-indigo-600 {
    background-color: #343261 !important;
}
.bg-indigo-700 {
    background-color: #5B5A99;
}
.bg-indigo-800 {
    background-color: #27244b;
}
.bg-indigo-900 {
    background-color: #1c1a42;
}
.bg-pfp {
    background-color: #282148;
}
.bg-cyan-500 {
    background-color: #50e3c2 !important;
}
.bg-cyan-400 {
    background-color: #00ccff;
}
.bg-gray-300 {
    background-color: #b1afcd;
}
.bg-gray-200 {
    background-color: #9796cf;
}
.bg-gray-900 {
    background-color: #111827;
}
.bg-gradient {
    background: linear-gradient(178.91deg, #1C1A42 0.94%, rgba(39, 36, 72, 0) 99.07%);
}
.justify-between {
    justify-content: space-between;
}
.justify-center {
    justify-content: center;
}
.justify-end {
    justify-content: end;
}
.items-center {
    align-items: center;
}
.items-start {
    align-items: start;
}
.items-end {
    align-items: end;
}
.flex {
    display: flex;
}
.flex-1 {
    flex: 1 1;
}
.flex-col {
    flex-direction: column;
}
.flex-wrap {
    flex-wrap: wrap;
}
.my-0 {
    margin-top: 0px;
    margin-bottom: 0px;
}
.mx-6 {
    margin: 0 24px;
}
.mt-1 {
    margin-top: .25rem;
}
.mt-2 {
    margin-top: .5rem;
}
.mt-3 {
    margin-top: .75rem;
}
.mt-4 {
    margin-top: 1rem;
}
.mt-5 {
    margin-top: 1.25rem;
}
.mt-7 {
    margin-top: 1.75rem;
}
.mt-9 {
    margin-top: 2.25rem;
}
.mt-10 {
    margin-top: 2.5rem;
}
.mt-12 {
    margin-top: 3rem;
}
.mt-16 {
    margin-top: 4rem;
}
.mt-18 {
    margin-top: 4.5rem;
}
.mt-20 {
    margin-top: 80px;
}
.mx-auto {
    margin-left: auto;
    margin-right: auto;
}
.container {
    width: 100%;
}
.w-0 {
    width: 0px;
}
.w-auto {
    width: auto;
}
.w-1\/5 {
    width: 20%;
}
.w-1\/6 {
    width: 16.67%;
}
.w-1\/7 {
    width: 14.29%;
}
.w-3\/4 {
    width: 75%;
}
.w-4\/5 {
    width: 80%;
}
.gap-x-14 {
    -webkit-column-gap: 3.5rem;
    column-gap: 3.5rem;
}
.gap-x-13 {
    -webkit-column-gap: 3.25rem;
    column-gap: 3.25rem;
}
.gap-x-12 {
    -webkit-column-gap: 3rem;
    column-gap: 3rem;
}
.gap-x-9 {
    -webkit-column-gap: 2.25rem;
    column-gap: 2.25rem;
}
.gap-x-8 {
    -webkit-column-gap: 2rem;
    column-gap: 2rem;
}
.gap-x-6 {
    -webkit-column-gap: 1.5rem;
    column-gap: 1.5rem;
}
.gap-x-5 {
    -webkit-column-gap: 1.25rem;
    column-gap: 1.25rem;
}
.gap-x-4 {
    -webkit-column-gap: 1rem;
    column-gap: 1rem;
}
.gap-x-3 {
    -webkit-column-gap: 0.625rem;
    column-gap: 0.625rem;
}
.gap-x-2 {
    -webkit-column-gap: 0.5rem;
    column-gap: 0.5rem;
}
.gap-x-1 {
    -webkit-column-gap: 0.25rem;
    column-gap: 0.25rem;
}
.gap-y-1 {
    row-gap: .25rem;
}
.gap-y-2 {
    row-gap: .5rem;
}
.gap-y-3 {
    row-gap: .625rem;
}
.gap-y-4 {
    row-gap: 1rem;
}
.gap-y-5 {
    row-gap: 1.5rem;
}
.gap-y-6 {
    row-gap: 1.75rem;
}
.gap-y-7 {
    row-gap: 1.875rem;
}
.gap-y-8 {
    row-gap: 2rem;
}
.gap-y-9 {
    row-gap: 2.25rem;
}
.gap-y-10 {
    row-gap: 2.5rem;
}
.gap-y-12 {
    row-gap: 3rem;
}
.gap-y-40 {
    row-gap: 10rem;
}
.vert_menu {
    row-gap: 30px;
}
.vert_menu a {
    opacity: .6;
}
.vert_menu a.active {
    opacity: 1;
    font-weight: 700;
}
.list-style {
    list-style: none;
}
.land-plot {
    height: 233px;
}
.land-plot:hover .bg-plot {
    display: flex;
}
.land-plot .bg-plot {
    display: none;
}
.bg-plot {
    background-color: rgba(39, 36, 72, 0.7);
}
.timepicker .ant-picker-footer {
    display: none;
}
textarea {
    padding: 10px;
}
.hidden {
    display: none;
}
.opacity-30 {
    opacity: .3;
}
.opacity-50 {
    opacity: .5;
}
.opacity-80 {
    opacity: .8;
}
.opacity-90 {
    opacity: 0.9;
}
.opacity-100 {
    opacity: 1;
}
.transform {
    transform: translateX(0) translateY(0) rotate(0) skewX(0) skewY(0) scaleX(1) scaleY(1);
}
.transition-opacity {
    transition-property: opacity;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
}
.transition-all {
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
}
.bg-opacity-80 {
    opacity: 0.8;
}
.font-normal {
    font-weight: 400!important;
}
.font-medium {
    font-weight: 500;
}
.font-large {
    font-weight: 600;
}
.font-bold {
    font-weight: bold;
}
.fill-white {
    fill: #fff;
}
.fill-black {
    fill: #000;
}
.text-zinc-300 {
    color: rgba(216,216,216,1);
}
.text-pink-400 {
    color: #FF409A;
}
a.text-pink-400:hover {
    color: #ff409a;
}
.text-green-500 {
    color: #00FF38;
}
.text-green-400 {
    color: #12A80F;
}
.text-wrap {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.button--gradient {
    background: linear-gradient(136.67deg,#ff409a 8.34%,#c438ef 95.26%) !important;
    box-shadow: 0 4px 31px #ba1358 !important;
    height: 30px!important;
    line-height: 16px!important;
    font-weight: 700!important;
}
.button-play {
    background: #C438EF !important;
}
.button-pulse {
    box-shadow: 0 0 0 0 rgba(90, 153, 212, 1);
    animation: pulse 2s infinite;
}
.button--sign {
    background-color: #2b2850;
    color: #fff;
    padding: 10px;
    height: 60px;
    /*width: 440px;*/
    width: 100%;
    text-align: left;
}
.button--sign:hover,
.button--sign:focus {
    background-color: #2b2850;
    color: #fff;
    border: none;
}
.button--img {
    width: 32px;
    height: 32px;
    margin-right: 10px;
}
.uppercase {
    text-transform: uppercase;
}
.capitalcase {
    text-transform: capitalize;
}
.underline {
    text-decoration: underline!important;
}
.rounded-md {
    border-radius: 0.375rem !important;
}
.rounded-lg {
    border-radius: 0.5rem;
}
.rounded-xl {
    border-radius: 0.75rem;
}
.rounded-10px {
    border-radius: 0.675rem !important;
}
.rounded-2xl {
    border-radius: 1rem !important;
}
.rounded-20px {
    border-radius: 1.25rem !important;
}
.rounded-3xl {
    border-radius: 1.5rem;
}
.rounded-5xl {
    border-radius: 2.5rem;
}
.rounded-r-0 {
    border-top-right-radius: 0!important;
    border-bottom-right-radius: 0!important;
}
.rounded-l-0 {
    border-top-left-radius: 0!important;
    border-bottom-left-radius: 0!important;
}
.rounded-t-xl {
    border-top-left-radius: 0.75rem;
    border-top-right-radius: 0.75rem;
}
.rounded-r-xl {
    border-top-right-radius: 0.75rem;
    border-bottom-right-radius: 0.75rem;
}
.rounded-l-xl {
    border-top-left-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem;
}
.rounded-b {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
}
.rounded-b-xl {
    border-bottom-left-radius: 0.75rem;
    border-bottom-right-radius: 0.75rem;
}
.rounded-b-2xl {
    border-bottom-right-radius: 1rem;
    border-bottom-left-radius: 1rem;
}
.hover\:bg-indigo-800:hover {
    background-color: #27244b !important;
}
.hover\:bg-indigo-600:hover {
    background-color: #343261 !important;
}
.hover\:bg-cyan-400:hover {
    background-color: #00ccff !important;
}
.hover\:bg-cyan-500:hover {
    background-color: #06b6d4 !important;
}
.hover\:bg-gray-300:hover {
    background-color: #9796cf !important;
}
.hover\:bg-pink-400:hover {
    background-color: #FF409A !important;
}
.hover\:bg-pink-300:hover {
    background-color: #E3507A;
}
.hover\:text-cyan-500:hover {
    color: #06b6d4 !important;
}
.hover\:text-indigo-800:hover {
    color: #2B2850 !important;
}
.outline-none {
    outline: none;
}
.focus\:outline-none:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
}
.focus\:bg-cyan-400:focus {
    background-color: #00ccff !important;
}
.focus\:bg-indigo-700:focus {
    background-color: #5B5A99 !important;
}
.focus\:bg-pink-400:focus {
    background-color: #FF409A !important;
}
.shadow-none {
    box-shadow: 0 0 #0000!important;
}
.shadow-md {
    box-shadow: 0 0 #0000, 0 0 #0000, 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}
.border {
    border-width: 1px;
}
.border-0 {
    border: none !important;
}
.border-2 {
    border-width: 2px;
}
.border-t-4 {
    border-top-width: 4px;
}
.border-b-4 {
    border: 0;
    border-bottom-width: 4px;
}
.border-b {
    border-width: 0;
    border-bottom-width: 1px;
}
.border-pink-500 {
    border-color: #FF409A;
}
.border-indigo-600 {
    border-color: #343261;
}
.border-indigo-800 {
    border-color: #27244b;
}
.border-indigo-700 {
    border-bottom-color: #5B5A99;
}
.border-b-indigo-600 {
    border-bottom-color: #343261;
}
.border-solid {
    border-style: solid;
}
.fixed {
    position: fixed;
}
.inset-0 {
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
}
.z-1 {
    z-index: 1;
}
.z-2 {
    z-index: 2;
}
.z-5 {
    z-index: 5;
}
.z-10 {
    z-index: 10;
}
.z-20 {
    z-index: 20;
}
.z-21 {
    z-index: 21;
}
.z-50 {
    z-index: 50;
}
.z-111 {
    z-index: 111;
}
.h-full {
    height: 100%;
}
.h-auto {
    height: auto;
}
.h-400 {
    height: 400px;
}
.h-420 {
    height: 420px;
}
.h-320 {
    height: 320px;
}
.h-400 {
    height: 400px;
}
.h-46 {
    height: 46px;
}
.h-40 {
    height: 10rem;
}
.h-60 {
    height: 15rem;
}
.h-70 {
    height: 17rem;
}
.h-10 {
    height: 2.5rem;
}
.h-11 {
    height: 2.75rem;
}
.h-14 {
    height: 14px;
}
.h-6 {
    height: 1.5rem;
}
.h-0 {
    height: 0px;
}
.min-h-267 {
    min-height: 267px;
}
.min-h-24 {
    min-height: 24px;
}
.min-h-15 {
    min-height: 15px;
}
.w-full {
    width: 100% !important;
}
.w-fit {
    width: fit-content;
}
.w-10 {
    width: 2.5rem
}
.w-12 {
    width: 3rem
}
.w-14 {
    width: 14px;
}
.w-3 {
    width: 0.625rem;
}
.w-5 {
    width: 1.25rem;
}
.w-6 {
    width: 1.5rem;
}
.w-8 {
    width: 2rem;
}
.w-60 {
    width: 15rem;
}
.footer {
    z-index: 1;
    position: relative;
    border-top: 1px solid #5b5a99;
    min-height: 469px;
}
.footer-info {
    padding-top: 60px;
}
.footer-list {
    display: flex;
    flex-direction: column;
    row-gap: 14px;
}
.footer-list li {
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
}
.footer-list li a {
    color: #fff;
}
.footer-list li a:hover {
    color: #06b6d4;
}
.footer-list li img {
    height: 16px;
    margin-right: 1px;
    margin-top: -1px;
}
.footer-title {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    font-family: 'Montserrat';
    color: #fff;
}
.footer-copyright {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    font-family: 'Montserrat';
    color: #B1AFCD;
}
.footer-copyright a {
    color: #B1AFCD;
}
.footer-copyright a:hover {
    color: #06b6d4;
}
.footer-logo {
    height: 46px;
}
.footer-bottom {
    padding: 32px 0;
}
.bottom-bar { position: fixed; bottom: 0; left: 0;  }
.bottom-bar-info { padding-left: 40px }
.page-images  { height: 200px; padding: 10px 0; border-bottom: 1px solid #ccc; }
.page-images img {  height: 180px; }
.page-images-buttons { float: right;
    width: 150px;
    padding: 10px 5px;
    text-align: center;
    margin-right: 20px;
}
.appearance-none {
    -webkit-appearance: none;
    appearance: none;
}
.waiting-popup { z-index: 999999999 !important }
.approve-modal, .mint-modal { padding: 30vh 30vw; z-index: 9999999999 !important; text-align: center }
.cursor-pointer {
    cursor: pointer;
}
.cursor-not-allowed {
    cursor: not-allowed;
}
.animate-spin {
    animation: spin 1s linear infinite;
}
/* Safari */
@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
.relative {
    position: relative;
}
.absolute {
    position: absolute;
}
.top-0 {
    top: 0;
}
.top-50,
.top-1\/2 {
    top: 50%;
}
.top-160 {
    top: 160px;
}
.left-0 {
    left: 0;
}
.left-50 {
    left: 50%;
}
.right-0 {
    right: 0;
}
.right-5 {
    right: 1.25rem;
}
.translate-y-50 {
    transform: translateY(-50%);
}
.pt-162 {
    padding-top: 164px;
}
.min-h-calc-head {
    min-height: 100vh;
}

.max-h-screen {
    max-height: 100vh;
}
.overflow-y-auto {
    overflow-y: auto;
}
.overflow-hidden {
    overflow: hidden;
}
.overflow-clip {
    overflow: clip;
}
.statusbar .ant-progress-bg {
    height: 4px!important;
}
.statusbar .ant-progress-inner {
    position: absolute;
    top: 0;
}
.statusbar .ant-progress-outer {
    padding-right: 0;
}
.statusbar .ant-progress-text {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}
.placeholder {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 244px;
}
.modal-root .ant-modal-centered .ant-modal {
    z-index: 1000!important;
}
.modal-root .ant-modal-body {
    padding: 0!important;
}
.modal-root #readyplayermeIframe {
    height: 555px!important;
    position: relative!important;
    top: 0!important;
    left: 0!important;
    padding: 0!important;
}
.modal-90p {
    width: 90%!important;
    height: 90%;
}
.modal-90p .ant-modal-content {
    height: 100%;
}
.modal-content-center .ant-modal-content {
    display: flex;
    align-items: center;
    justify-content: center;
}
.modal-center {
    width: 100%!important;
    border-radius: 0;
    margin: 0px;
    height: 100vh;
    top: 0px;
    max-width: 100%;
    padding-bottom: 0px;
}

.modal-center .ant-modal-content {
    border-radius: 0;
    height: 100%;
    background-color: rgba(28, 26, 66, .8);
}

.modal-center .ant-modal-body {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.modal .ant-modal-content {
    background-color: #1c1a42;
    border-radius: 10px;
}
.modal .ant-modal-close-icon svg {
    fill: white;
}
.modal-logo .ant-modal-content {
    background-color: transparent;
    border-radius: 0;
    box-shadow: none;
}
.modal-logo {
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
    padding: 0;
    border-radius: 20px;
    -webkit-border-radius: 20px;
}
.modal-logo .ant-modal-body {
    padding: 63px;
}
.modal-logo .ant-btn.bg-pink-300:focus {
    background-color: #E3507A;
}
.modal-logo .ant-btn.bg-gray-200:focus {
    background-color: #9796cf;
}
.drop-icon {
    transform: rotate(90deg);
    display: block;
    color: #b1afcd;
    font-size: 22px;
}
/*.switch.ant-switch {
    height: 33px;
    min-width: 59px;
}
.switch.ant-switch-checked:focus {
    box-shadow: none;
}
.switch .ant-switch-handle {
    height: 33px;
    min-width: 33px;
    top: 0;
    left: 0;
}
.switch .ant-switch-handle::before {
    border-radius: 50%;
}
.switch.ant-switch-checked {
    background: #5B5A99
}
.switch.ant-switch-handle {
    left: 0;
}
.switch.ant-switch-checked .ant-switch-handle {
    left: auto;
    right: 0;
}
.switch.ant-switch-checked .ant-switch-handle:before {
    background-color: #00FFFF;
}*/

.switch {
    position: relative;
    display: inline-block;
    width: 59px;
    height: 33px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #5B5A99;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 33px;
    width: 33px;
    left: 0px;
    bottom: 0px;
    -webkit-transition: .4s;
    transition: .4s;
}
.slider.true:before {
    background-color: #00FFFF;
}
.slider.false:before {
    background-color: #BCBAF9;
}
input:checked + .slider {
    background-color: #5B5A99;
}

input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

.input-prefix {
    padding: 6px 17px 6px 45px;
}

.input-prefix + i {
    content: "\f099";
    font-family: "metaicon-v1";
    position: absolute;
    left: 17px;
    top: 50%;
    transform: translateY(-50%);
    color: #fff;
    font-size: 21px;
}

.w-100 {
    width: 100%;
}

.w-fit {
    width: fit-content;
}

.app-aaa { width: 99%; }
/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}
.ant-message-notice-content {
    padding: 9px 12px;
    border-radius: 8px;
}
.ant-spin-spinning {
    max-height: fit-content!important;
}
.ant-spin-container {
    opacity: 1;
}
.ant-spin-container:after {
    content: none;
}
.ant-alert-message {
    color: #fff!important;
}
.ant-carousel .slick-dots li {
    height: 12px;
    width: 12px;
    margin: 0 6px;
}
.ant-carousel .slick-dots li button {
    height: 12px;
    border-radius: 20px;
}
.ant-carousel .slick-dots li.slick-active button {
    width: 28px;
}
.pay-progress .ant-progress-text {
    color: #fff;
}

.pay-progress .ant-progress-circle-path {
    animation-name: rotate;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

@keyframes rotate{
    from{ transform: rotate(-90deg); }
    to{ transform: rotate(270deg); }
}

@keyframes pulse {
    0% {
        box-shadow: 0 0 0 0 rgba(90, 153, 212, 0.9);
    }

    70% {
        box-shadow: 0 0 0 10px rgba(90, 153, 212, 0);
    }

    100% {
        box-shadow: 0 0 0 0 rgba(90, 153, 212, 0);
    }
}


.custom-select {
    color: #fff;
    margin-bottom: 20px;
    background-color: #1C1A42;
    border: none;
    padding: 10px;
    border-radius: 10px;
    font-size: 14px;
    width: 100%;
}

.custom-select .ant-select-selector {
    background-color: #1C1A42!important;
    border: none!important;
    box-shadow: none!important;
}

.custom-select .ant-select-arrow .anticon > svg {
    fill: #fff!important;
}

.custom-select.ant-select-single .ant-select-selector:after {
    color: #fff!important;
}

.country-select {
    background-color: #5b5a99;
    border-radius: 0.375rem;
    padding: 7px 0;
}

.country-select .ant-select-selector {
    background-color: #5b5a99 !important;
}


.success-transaction { width: 100px }

.modal-middle {
    top: 200px;
    width: 460px!important;
}

.vertical-divider {
    height: 100%;
    width: 2px;
    background-color: #2b2850;
}


#readyplayermeIframe {
    position: fixed;
    z-index: 99999999;
    top : 10px;
    left: 10%;
    width: 1080px;
    height: 665px;
    margin: 0;
    padding: 20px;
    border: none;
}

/* width */
::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #00ccff;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #171435;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #2b2850;
}

#fixed-bg-frame {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99999;
    background-color: rgba(20, 16, 16, 0.463);
}

.user-dropdown {
    background: #343261;
    position: relative;
    border-radius: 0.625rem;
    -webkit-border-radius: 0.625rem;
    margin-top: 16px;
    padding: 15px 0;
    min-width: 154px;
    transform: translate(-15px, 3px);

}
.user-dropdown:after {
    content: "";
    position: absolute;
    top: -16px;
    left: 50%;
    transform: translateX(-50%);
    border-left: 13px solid transparent;
    border-right: 13px solid transparent;
    border-bottom: 16px solid #343261;
}
.user-dropdown > li {
    padding: 0;
}
.user-dropdown > li:hover,
.user-dropdown > li.ant-dropdown-menu-item-active {
    background-color: transparent;
}
.user-dropdown > li a {
    padding: 15px 30px;
    display: block;
    color: #fff;
    font-size: 18px;
    font-weight: 300;
    font-family: "Roboto";
}
.user-dropdown > li a:hover {
    color: #00ccff;
}
.user-box {
    /*border: 2px solid #5B5A99;*/
    /*border-radius: 12px;*/
    /*-webkit-border-radius: 12px;*/
    border: none;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    overflow: hidden;
    background-repeat: no-repeat;
    background-size: cover;
    width: 36px;
    min-width: 36px;
    height: 36px;
}
.user-avatar {
    transform: scale(2) translateY(17%);
    object-fit: cover;
}
.wallet-dropdown {
    transform: translate(-85px, 5px);
}
.wallet-dropdown > li {
    padding: 13px 30px;
}
.status-dropdown {
    transform: translate(-35px, -5px);
}
.status-dropdown > li {
    padding: 0px 18px;
}
.login-note {
    background: rgba(27, 24, 63, 0.8);
    padding: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    column-gap: 12px;
    row-gap: 4px;
    font-weight: 300;
    font-size: 1rem;
    line-height: 1.125rem;
    color: #fff;
}
.login-input {
    height: 40px;
    padding: 6px 10px;
    font-size: 16px;
    font-weight: 500;
}
.login-form {
    margin: 0 auto;
    padding: 24px 66px;
}
.login-btn {
    min-width: 180px;
    height: 38px;
}
.register-form {
    margin: 0 auto;
    padding: 24px 66px;
}
.metamask-btn {
    background-color: #fff;
    padding: 10px;
    height: 50px;
    min-width: 230px;
    margin: 0 auto;
    text-align: left;
}
.form-box {
    border: 4px solid transparent;
    border-radius: 20px;
    background: linear-gradient(rgb(27, 24, 63), rgba(27, 24, 63, 0.8)) padding-box,
    linear-gradient(to bottom, rgb(203, 23, 233), rgba(192,30,213,0)) border-box;
}
.input-error {
    border: 1px solid red !important;
}
.input-error:focus-visible {
    outline: 1px solid red;
}

/*Get Map Land*/

.thumb:hover .thumb-remove {
    display: flex!important;
    align-items: center!important;
}
.thumb-remove {
    display: none!important;
    position: absolute!important;
    top: 0;
    right: 0;
    z-index: 1;
    padding: 0 9px!important;
    border-radius: 50% !important;
    background-color: #1c1a42!important;
    margin: 6px 6px 0 0;
}
.thumb-remove i {
    font-size: 16px;
    height: 12px!important;
    line-height: 12px!important;
}
.gland-intro {
    height: 185px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    margin-bottom: 60px;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}
.gland-intro_ttl {
    font-size: 28px;
    line-height: 34px;
}
.gland-info_ttl {
    color: #fff;
    text-transform: uppercase;
    font-weight: 500;
}
.gland-info_text {
    font-size: 16px;
    line-height: 26px;
}
.gland-info_list {
    font-size: 16px;
    line-height: 28px;
    padding-left: 20px;
}
.gland-details {
    border-radius: 10px;
    -webkit-border-radius: 10px;
    background-color: #1c1a42;
    padding: 23px;
}
.gland-details_ttl {
    color: #9796CF;
    font-size: 12px;
    line-height: 15px;
    font-weight: 500;
    text-transform: uppercase;
}
.gland-details_icon {
    height: 16px;
}
.gland-list_ttl {
    font-size: 16px;
    line-height: 20px;
    color: #BCBAF9;
}
.gland-list_header {
    margin-bottom: 42px;
}
.gland-pay {
    padding: 23px 25px;
    background-color: #1C1A42;
    border-radius: 20px 20px 0px 0px;
    -webkit-border-radius: 20px 20px 0px 0px;
}
.gland-pay_ttl {
    font-size: 12px;
    line-height: 15px;
    color: #9796CF;
    margin-bottom: 20px;
    text-transform: uppercase;
    font-weight: bold;
}
.gland-pay_check {
    row-gap: 16px;
}
.gland-pay-token.ant-radio-wrapper {
    margin-right: 0!important;
}
.gland-pay-token .ant-radio + span {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 16px;
    padding-right: 0;
}
.gland-pay-token .ant-radio-inner {
    width: 14px;
    height: 14px;
}
.gland-pay_label {
    font-size: 13px;
    line-height: 16px;
    min-width: 125px;
}
.gland-pay-type {
    font-size: 10px;
    line-height: 12px;
    color: #5B5A99;
}
.gland-pay-discount {
    font-size: 10px;
    line-height: 12px;
    color: #00FF38;
}
.gland-amount {
    padding: 23px 25px;
    background-color: #1C1A42;
    border-radius: 0px 0px 20px 20px;
    -webkit-border-radius: 0px 0px 20px 20px;
    overflow: hidden;
}
.gland-amount_price {
    row-gap: 2px;
    margin-bottom: 24px;
}
.gland-amount_row {
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 40px;
}
.gland-amount_ttl {
    font-size: 10px;
    line-height: 12px;
    color: #9796CF;
    margin-bottom: 20px;
    text-transform: uppercase;
    font-weight: bold;
}
.gland-amount_usd {
    font-size: 9px;
    line-height: 10px;
    color: #BCBAF9;
}
.gland-amount_discount {
    margin: 24px 0;
    font-size: 12px;
    line-height: 16px;
    color: #00FF38;
}
.gland-amount_note {
    font-size: 12px;
    line-height: 14px;
    margin-top: 24px;
}
.gland-amount_btn {
    width: 105px;
    height: 30px!important;
    margin-top: 30px;
}
.gland-loading_btn {
    height: 36px!important;
    width: 180px;
}
.gland-claim_note {
    font-size: 10px;
    line-height: 12px;
    color: #BCBAF9;
}
.gland-claim_btn {
    height: 26px;
    line-height: 15px;
    width: 75px;
}
.gland-amount_input {
    width: 40px;
    height: 20px;
    padding: 4px;
    font-size: 14px;
    line-height: 17px;
}
.gland-amount_input {
    outline: 1px solid transparent;
}
.gland-amount_limit {
    font-size: 9px;
    line-height: 11px;
    color: #9796CF;
}
.thumb-metahut_avatar {
    /*border: 1px solid #5B5A99;*/
    /*border-radius: 8px!important;*/
    /*-webkit-border-radius: 8px!important;*/
    border: none;
    border-radius: 50%!important;
    -webkit-border-radius: 50%!important;
    overflow: hidden;
}
.thumb-ctg_visitor {
    background-color: #EF5261;
    color: #fff;
}
.thumb-asset_metahut {
    padding: 11px 19px;
}
.thumb-asset_metahut .thumb-ctg {
    padding: 4px 8px;
    margin: 0;
    font-size: 12px;
    line-height: 14px;
}
.thumb-metahut_username {
    font-size: 16px;
    line-height: 19px;
    color: #1F8FF6;
}
.discovery-title {
    font-size: 28px;
    line-height: 34px;
    color: #9796CF;
}
.discovery-follow {
    height: 22px;
    font-size: 12px;
    line-height: 15px;
    padding: 4px 11px;
}
.discovery-subject {
    column-gap: 10px;
}
.discovery-row {
    row-gap: 60px;
}
.discovery-row .thumb {
    transition: box-shadow 0.25s ease-in-out 0s, transform 0.25s ease 0s;
}
.discovery-row .thumb:hover {
    box-shadow: rgba(0, 0, 0, 0.12) 0px 4px 16px;
    transform: translate(0px, -8px);
}
.discovery-menu a {
    color: #9796cf ;
}
.discovery-menu a:hover {
    color: #fff;
}
.thumb-claim_page {
    padding: 26px 10px;
}
.thumb-claim_asset {
    height: 158px;
}
.counter {
    display: inline-block;
    padding: 3px;
    height: 20px;
    min-width: 20px;
    font-size: 12px;
    line-height: 14px;
    color: #BCBAF9;
    background-color: #3F3C77;
    border-radius: 5px;
    -webkit-border-radius: 5px;
}
.discovery-menu .active span {
    color: #fff!important;
}
.level-part {
    justify-content: space-around;
    margin-left: -13px;
    width: 109%;
}
.level-part > div {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.level-line > div {
    width: calc(100%/9);
    height: 3px;
    background-color: #fff;
}
.level-percent {
    font-weight: 400;
    font-size: 8px;
    line-height: 10px;
}
.level-y {
    width: 1px;
    height: 6px;
    background-color: #fff;
}
.level-line div:first-child {
    border-radius: 5px 0 0 5px;
}
.level-line div:last-child {
    border-radius: 0 5px 5px 0;
}
.discount-note {
    font-size: 10px;
    line-height: 12px;
}
.discount-percent .level-percent {
    color: #00ff38;
}
.discount-percent .level-y {
    background-color: #00ff38;
}
.level-line > div.discount-line {
    background-color: #00ff38;
}
.metahut-carousel {
    padding-bottom: 30px;
}
.metahut-carousel .slick-list {
    border-right: 10px;
    -webkit-border-radius: 10px;
    overflow: hidden;
}
.metahut-carousel .slick-dots-bottom {
    bottom: 0;
    margin-bottom: 0;
}
.metahut-carousel .slick-dots li {
    height: 6px;
    width: 6px;
    margin: 0 5px;
}
.metahut-carousel .slick-dots li button,
.metahut-carousel .slick-dots li.slick-active button {
    width: 100%;
    height: 100%;
}
.metahut-carousel .slick-dots li.slick-active {
    width: 12px;
}
.metahut-carousel img {
    height: 200px;
    border-right: 10px;
    -webkit-border-radius: 10px;

}
.metahut-intro {
    height: 260px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    margin-bottom: 60px;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}
.metahut-tab {
    display: flex;
    align-items: center;
    column-gap: 5px;
}
.metahut-tab .ant-radio-button-wrapper {
    background: #2C2955!important;
    border-radius: 15px 15px 0px 0px;
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    font-family: 'Montserrat';
    text-transform: uppercase;
    text-align: center;
    color: #5B5A99;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: none!important;
}
.metahut-tab .ant-radio-button-wrapper-checked {
    background: #1C1A42!important;
    color: #9796CF!important;
}
.metahut-tab .ant-radio-button-wrapper:before {
    content: none;
}
.metahut-pay {
    border-radius: 0px 0px 20px 20px;
    -webkit-border-radius: 0px 0px 20px 20px;
}
.metahut-amount {
    border-radius: 20px 20px;
    -webkit-border-radius: 20px 20px;
}
.download a {
    opacity: 1;
}
.download a:hover {
    opacity: .8;
    transition: opacity .3s ease-in-out;
}
.video-layout {
    width: 100%;
    height: 100vh;
    position: relative;
    transition: 0.5s;
    overflow: hidden;
}
.video-bg {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    object-fit: cover;
}
.video-gradient {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, #1C1A42 0%, rgba(28, 26, 66, 0) 100%);
}
.video-content {
    z-index: 2;
    position: relative;
    max-width: 640px;
    margin-top: 110px;
    row-gap: 50px;
}
.video-content-title {
    font-size: 48px;
    line-height: 59px;
}
.video-3d-title {
    font-size: 38px;
    line-height: normal;
}
.video-3d-desc {
    font-size: 25px;
    line-height: normal;
}
.video-content-desc {
    font-size: 20px;
    line-height: 28px;
}
.video-btn-link {
    width: 232px;
}
.video-content-btn,
.video-content-btn:focus,
.video-content-btn:active {
    width: 232px;
    height: 46px!important;
    background-color: #FF409A;
    font-size: 14px;
    line-height: 17px;
    column-gap: 10px;
    color: #fff;
}
.video-content-btn:hover {
    background-color: #1890ff;
    color: #fff;
}
.tcard-pfp {
    padding: 3rem;
    border-radius: 40px;
}
.tcard-price {
    font-size: 4rem;
    line-height: normal;
}
.tcard-label {
    font-size: 22px;
}
.tcard-button {
    background-color: #fff;
    padding: 10px;
    border-radius: 14px;
    min-width: 130px;
}
.join-steps {
    row-gap: 70px;
    margin-bottom: 112px;
}
.join-header {
    max-width: 700px;
    row-gap: 30px;
}
.join-header-title {
    font-size: 38px;
    line-height: 46px;
}
.join-header-desc {
    font-size: 16px;
    line-height: 24px;
}
.join-box {
    position: relative;
    min-height: 400px;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    row-gap: 35px;
    padding: 57px 15px;
}
.join-box-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    background: rgba(28, 26, 66, 0.5);
}
.join-box-title {
    font-size: 36px;
    line-height: 44px;
    position: relative;
    z-index: 1;
}
.join-box-desc {
    font-size: 18px;
    line-height: 28px;
    min-height: 84px;
    position: relative;
    z-index: 1;
}
.join-box-link {
    margin-top: 35px;
    position: relative;
    z-index: 1;
}
.join-box-btn {
    width: 154px;
    height: 40px!important;
}
.join-box-btn:hover {
    background-color: #3F3C77;
    color: #fff!important;
}
.pfp-avatars-bg {
    background-color: #1B163F;
    padding-top: 34px;
    padding-bottom: 98px;
    margin-bottom: 150px;
    margin-top: 106px;
}
.pfp-avatars-row {
    row-gap: 70px;
}
.avatars-3d_img {
    margin-top: -70px;
    width: 136%;
}
.avatars-3d_ttl {
    font-size: 2.2rem;
    line-height: normal;
}
.mac-version {
    position: relative;
}
.mac-version img {
    filter: blur(2px);
    opacity: .3;
}
.mac-version span {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    padding: 10px;
    text-align: center;
}

.os-btn {
    position: relative;
    width: 232px;
}
.os-btn button {
    filter: blur(2px);
    opacity: .3;
}
.os-btn > span {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    padding: 10px;
    text-align: center;
}

/* Page404 */
.page404 {
    width: 100%;
    position: fixed;
    height: 100vh;
    z-index: 20;
    padding-top: 0;
    margin-top: 93px;
}
.page404-container {
    display: flex;
    align-items: center;
    justify-content: center;
}
.page404-btn {
    font-size: 14px;
    line-height: 17px;
    background-color: #5b5a99;
    height: 60px;
    min-width: 240px;
}
.page404-btn:hover {
    background-color: #fff!important;
    color: #5b5a99!important;
}
.page404-symbol {
    font-size: 96px;
    line-height: 117px;
    font-weight: 800;
    margin-bottom: 40px;
}
.page404-title {
    font-size: 36px;
    line-height: 44px;
    margin-bottom: 35px;
}
.page404-text {
    font-size: 24px;
    line-height: 29px;
    margin-bottom: 50px;
}
.page404-content {
    text-align: center;
    transform: translateY(-50px);
}
.page404:has
.page404 + .footer {
    display: none;
}

/*Staking*/
.staking-pool {
    overflow: auto hidden;
}
.staking .text-gray-300 {
    color: #B1AFCD!important;
}
.staking .bg-indigo-800 {
    background-color: #2B2850;
}
.staking .border-indigo-600 {
    border-color: #5B5A99;
}
.staking .text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
}
.staking .h-14 {
    height: 3.5rem;
}
.staking .gap-y-3 {
    row-gap: 0.75rem;
}
.card.stake-details {
    min-height: unset;
    border-radius: 0.75rem;
}
.animate-bg {
    background-position-y: center;
    background-position-x: right;
    position: relative;
}
.animate-bg:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(28, 26, 66, .5);
}

/*Unstake Modal*/
.unstake .gap-y-5 {
    row-gap: 1.5rem;
}

/* Package colors */
.color-1 {
    color: #1789F6 !important;
}
.color-2 {
    color: #46B57F !important;
}
.color-3 {
    color: #EADD76 !important;
}
.color-mint {
    color: #53DD69;
}
.color-burn {
    color: #EF5261;
}
@media (max-width: 1199px) {
    .info {
        flex-direction: column;
        padding-top: 100px;
        row-gap: 15px;
    }
}
@media (max-width: 768px) {
    .video-3d {
        height: 100%;
        display: flex;
        align-items: center;
    }
    .container.px-12 {
        padding-left: 1rem;
        padding-right: 1rem;
    }
    .register-form {
        padding-left: 0;
        padding-right: 0;
    }
    .settings-layout:after {
        content: none;
    }
    .settings-title {
        margin-top: 60px;
    }
    .input-search {
        width: 100%;
        margin-top: 20px;
    }
    .video-content {
        margin-top: 50px;
    }
    .video-content-title {
        font-size: 48px;
        line-height: 59px;
    }
    .video-content-desc {
        font-size: 20px;
        line-height: 28px;
    }
    .video-btn-link {
        display: none;
    }
    .dashboard {
        flex-direction: column;
    }
    .content-left,
    .content-right {
        width: 100%;
    }
    .badge {
        padding: 25px;
    }
    .page404-symbol {
        font-size: 76px;
        line-height: 75px;
        margin-bottom: 30px;
    }
    .page404-title {
        font-size: 36px;
        line-height: 44px;
        margin-bottom: 35px;
    }
    .page404-text {
        font-size: 20px;
        line-height: 28px;
    }
    .page404-btn {
        height: 50px;
        min-width: 200px;
    }
    .staking-pool {
        width: max-content!important;
    }
    .pool-header {
        width: 100%;
    }
    .events-sider {
        padding-top: 4rem;
    }
    .settings-layout {
        flex-direction: column!important;
    }
    .eform-picker.ant-picker,
    .ehosts-search {
        width: 100%;
    }
    .ereward-amount .eform-input {
        max-width: 100%;
    }
    .eform-group {
        margin-bottom: 64px;
    }
    .avatars-3d_img {
        width: 100%;
    }
}
@media (max-width: 576px) {
    .video-content {
        margin-top: 30px;
    }
    .video-content-title {
        font-size: 42px;
        line-height: 52px;
    }
    .video-content-desc {
        font-size: 18px;
        line-height: 26px;
    }
    .info-right {
        flex-direction: column;
        row-gap: 20px;
    }
    .social {
        padding-left: 0;
        padding-top: 20px;
        border-left-width: 0;
        border-top: 1px solid #5b5a99;
    }
}
@media (max-width: 992px) {
    /*.container {*/
    /*    padding-left: 0!important;*/
    /*    padding-right: 0!important;*/
    /*}*/
    .pt-162 {
        padding-top: 94px;
    }
    .badge {
        width: calc((100% / 2) - 18px);
        padding: 20px;
    }
    .page404 {
        padding-top: 0!important;
    }
    .staking-pool {
        width: 100%;
    }
    .pool-body {
        width: 90%;
    }
    .events-menu {
        position: fixed;
        top: 0;
        right: -100%;
        z-index: 20;
        background-color: #27244b;
        transition: all .3s ease-in-out;
        max-width: 100%;
    }
    .events-menu.show {
        right: 0;
    }
    .events-menu button {
        font-size: 18px;
        color: #fff;
        display: flex;
        align-items: center;
        column-gap: 10px;
        padding: 15px;
        height: auto;
    }
    .events-menu button:focus {
        color: #fff;
    }
    .events-ctg_nav {
        padding: 0 20px 20px;
        height: calc(100vh - 60px);
        overflow: auto;
    }
    .events-ctg {
        flex: unset!important;
        width: auto!important;
        min-width: auto!important;
        max-width: unset!important;
    }
    .events-all {
        display: flex;
        align-items: center;
        column-gap: 2rem;
        margin-bottom: 37px;
    }
    .events-all button {
        color: #fff;
        font-size: 1rem;
        padding-left: 0;
        padding-right: 0;
    }
    .events-all button:focus {
        color: #fff;
    }
    .events-ctg-ttl {
        margin-bottom: 0;
    }
    .events-ctg .ant-layout-sider-children {
        height: auto;
    }
    .events-layout {
        display: flex;
        flex-direction: column!important;
    }
    .events-list {
        width: 100% !important;
    }
}

@media (min-width: 576px) {
    .container {
        max-width: 576px;
    }
    .ant-col-xs-24 {
        flex: 0 0 100%;
        max-width: 100%;
    }
    .col-sm-8 {
        display: block;
        flex: 0 0 33.33333333333333%;
        max-width: 33.33333333333333%;
    }
    .ant-col-sm-13 {
        flex: 0 0 53.5%;
        max-width: 53.5%;
    }
    .ant-col-sm-11 {
        flex: 0 0 46.5%;
        max-width: 46.5%;
    }
}
@media (min-width: 640px) {
    .sm\:block {
        display: block;
    }
    .sm\:p-0 {
        padding: 0px;
    }
    .sm\:pr-10 {
        padding-right: 0;
    }
    .sm\:align-middle {
        vertical-align: middle;
    }
    .sm\:max-w-3xl {
        max-width: 48rem;
    }
    .sm\:max-w-xs {
        max-width: 20rem;
    }
    .sm\:w-full {
        width: 100%;
    }
    .sm\:w-auto {
        width: auto;
    }
    .sm\:ml-3 {
        margin-left: 0.75rem;
    }
    .sm\:mt-0 {
        margin-top: 0px;
    }
    .sm\:my-8 {
        margin-top: 2rem;
        margin-bottom: 2rem;
    }
    .sm\:text-sm {
        font-size: 0.875rem;
        line-height: 1.25rem;
    }
}
@media (min-width: 768px) {
    .container {
        max-width: 768px;
    }
    .ant-col-sm-12 {
        flex: 0 0 50%;
        max-width: 50%;
    }
    .login-note {
        min-width: 579px;
    }
    .login-form {
        max-width: 531px;
        min-width: 450px;
    }
}
@media (min-width: 992px) {
    .container {
        max-width: 992px;
    }
    .ant-col-md-16 {
        flex: 0 0 66.66666666%;
        max-width: 66.66666666%;
    }
    .ant-col-md-13 {
        flex: 0 0 53.5%;
        max-width: 53.5%;
    }
    .ant-col-md-12 {
        flex: 0 0 50%;
        max-width: 50%;
    }
    .ant-col-md-11 {
        flex: 0 0 46.5%;
        max-width: 46.5%;
    }
    .ant-col-md-8 {
        flex: 0 0 33.33333333%;
        max-width: 33.33333333%;
    }
    .ant-col-lg-15 {
        flex: 0 0 62.5%;
        max-width: 62.5%;
    }
    .ant-col-lg-13 {
        flex: 0 0 53.5%;
        max-width: 53.5%;
    }
    .ant-col-lg-11 {
        flex: 0 0 46.5%;
        max-width: 46.5%;
    }
    .ant-col-lg-9 {
        flex: 0 0 37.5%;
        max-width: 37.5%;
    }
    .register-form {
        max-width: 805px;
    }
    .register-form-row {
        margin: 0;
    }
    .register-form-left {
        padding-left: 0;
    }
    .register-form-right {
        padding-right: 0;
    }
    .events-all,
    .events-menu button {
        display: none;
    }
}
@media (min-width: 992px) and (max-width: 1199px) {
    .ant-col-md-24 {
        flex: 0 0 100%;
        max-width: 100%;
    }
    .ant-col-md-8 {
        flex: 0 0 33.33333333%;
        max-width: 33.33333333%;
    }
}
@media (min-width: 1200px) {
    .container {
        max-width: 1200px;
    }
    .ant-col-lg-6 {
        flex: 0 0 25%;
        max-width: 25%;
    }
    .ant-col-lg-8 {
        flex: 0 0 33.33333333%;
        max-width: 33.33333333%;
    }
    .ant-col-lg-10 {
        flex: 0 0 41.66666667%;
        max-width: 41.66666667%;
    }
    .ant-col-lg-12 {
        flex: 0 0 50%;
        max-width: 50%;
    }
    .ant-col-lg-14 {
        flex: 0 0 58.33333333%;
        max-width: 58.33333333%;
    }
    .ant-col-lg-16 {
        flex: 0 0 66.66666666%;
        max-width: 66.66666666%;
    }
}

.list-metahut{ background-size: cover !important ; background-repeat: no-repeat !important; }

.gtm-custom-land-buying-success-meto { border: inherit }
.app-aa { display: block; }

/* .Toastify { z-index: 999999; position: fixed; } */
