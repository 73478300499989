.stake-table tr:last-child {
    border: none;
}

button:disabled,
button[disabled] {
    opacity: .5;
    cursor: not-allowed;
}

.animate-bg {
    background: url("../img/fire-effect-bg.gif");
    background-position-y: center;
    background-position-x: right;
    position: relative;
}
.animate-bg:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(28, 26, 66, .5);
}
.text-15px {
    font-size: 15px;
}
.text-17px {
    font-size: 17px;
}