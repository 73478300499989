.intro {
    border-radius: 20px;
    -webkit-border-radius: 20px;
    overflow: hidden;
}
.bg {
    height: 240px;
    position: relative;
}
.profile {
    position: absolute;
    bottom: -87px;
    left: 50%;
    transform: translateX(-50%);
}
.profile-avatar {
    border: 3px solid transparent;
    border-radius: 30px;
    -webkit-border-radius: 30px;
    overflow: hidden;
    width: 120px;
    min-width: 120px;
    height: 120px;
    background: linear-gradient(rgb(27, 24, 63), rgba(27, 24, 63, 0.8)) padding-box,
    linear-gradient(to bottom, rgb(221, 12, 225), rgba(144,90,153,0)) border-box;
}
.avatar-img {
    transform: scale(2) translateY(17%);
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.country-label {
    font-size: 20px;
    line-height: 23px;
    color: #9796CF;
}
.info {
    padding: 26px 23px;
    background-color: rgba(28, 26, 66, 0.8);
}
.info-left {
    column-gap: 20px;
}
.info-right {
    column-gap: 20px;
}
.status-icon {
    width: 48px;
    height: 48px;
}
.status-label {
    font-size: 20px;
    line-height: 23px;
}
.line-y {
    width: 1px;
    height: 40px;
    background-color: #5b5a99;
}
.line-x {
    background-color: #3F3C77;
    height: 1px;
}
.rank {
    font-size: 20px;
    line-height: 23px;
    color: #9796CF;
}
.name {
    font-size: 18px;
    line-height: 22px;
}
.username {
    font-size: 18px;
    line-height: 22px;
    opacity: .7;
}
.content-left {
    width: 381px;
}
.content-right {
    width: calc(100% - 381px);
    row-gap: 60px;
}
.meta-card {
    background-color: #1C1A42;
    padding: 29px 24px 36px;
    border-radius: 15px;
    -webkit-border-radius: 15px;
}
.meta-card-header {
    font-size: 18px;
    line-height: 21px;
    color: #9796CF;
}
.meta-card-list + .meta-card-list {
    margin-top: 30px;
}
.token {
    column-gap: 7px;
}
.icon {
    height: 24px;
}
.label {
    font-size: 16px;
    line-height: 19px;
}
.amount {
    font-size: 16px;
    line-height: 19px;
    color: #50E3C2;
}
.dashboard {
    column-gap: 65px;
    row-gap: 65px;
}
.thumb {
    display: block;
    border-radius: 15px;
    -webkit-border-radius: 15px;
    overflow: hidden;
}
.thumb-asset {
    height: 139px;
}
.thumb-info {
    row-gap: 15px;
    padding: 13px 10px 19px;
    background-color: #1C1A42;
}
.thumb-info_land {
    row-gap: 19px;
    padding: 15px 15px 13px;
}
.thumb-claim {
    padding: 15px 10px;
    background-color: #1C1A42;
}
.thumb-title {
    font-size: 13px;
    line-height: 15px;
    column-gap: 5px;
}
.thumb-title i {
    margin-top: 1px;
}
.stats {
    column-gap: 15px;
    color: #B1AFCD;
}
.stats-item {
    column-gap: 5px;
    row-gap: 5px;
}
.asset-link {
    font-size: 18px;
    line-height: 21px;
    color: #BCBAF9;
}
.assets-row {
    row-gap: 44px;
}
.assets-col {
    row-gap: 22px;
}
.thumb .avatar_box {
    border-radius: inherit;
    -webkit-border-radius: inherit;
}
.thumb-asset .bg-plot {
    display: none;
}
.thumb-asset:hover .bg-plot {
    display: flex;
}
.meta-nav {
    color: #9796CF;
}
.meta-nav:hover {
    color: #50E3C2;
}
.thumb-land_buy {
    column-gap: 15px;
}
.thumb-land_buy span {
    font-size: 16px;
    line-height: 19px;
}
.thumb-land_buy:hover {
    color: #00ffff!important;
}
.thumb-land {
    padding: 39px 18px;
    row-gap: 30px;
    background-color: #1C1A42;
}
.line-x_land {
    width: 90%;
    margin: 0 auto;
}
.thumb-land_locate {
    column-gap: 13px;
}
.thumb-land_locate span {
    font-size: 16px;
    line-height: 19px;
}
.thumb-land_locate i {
    font-size: 40px;
}
.thumb-land_locate:hover {
    color: #ff7979!important;
}
.badge-list {
    column-gap: 22px;
    row-gap: 22px;
    flex-wrap: wrap;
}
.badge {
    width: calc((100% / 4) - 18px);
    padding: 25px 35px;
    background-color: #1C1A42;
    border-radius: 15px;
    -webkit-border-radius: 15px;
    row-gap: 25px;
}
.badge-icon {
    height: 80px;
}
.badge-label {
    color: #9796CF;
    font-size: 12px;
    line-height: 14px;
}
.badge-empty {
    height: 169px;
    padding: 25px;
}
.badge-note {
    font-size: 14px;
    line-height: 16px;
}
.badge-icon_skeleton {
    width: 80px!important;
    height: 80px!important;
    border-radius: 50%;
    -webkit-border-radius: 50%;
}
.land-id {
    color: #00ffff;
}
.land-asset_skeleton {
    height: 139px!important;
    width: 100% !important;
    border-radius: 15px 15px 0 0 !important;
}
.social {
    border-left: 1px solid #5b5a99;
    padding: 8px 0 8px 20px;
}
.thumb-buy {
    height: 217px;
    border: 2px solid #5B5A99;
}
.thumb-buy_bg {
    height: 100%;
    width: 100%;
    filter: grayscale(1);
    opacity: .4;
}
.thumb-buy_content {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    padding: 15px;
    row-gap: 15px;
    background-color: rgba(43, 40, 70, .7);
}
.thumb-buy_note {
    font-size: 16px;
    line-height: 19px;
}
.thumb-buy_btn {
    border-radius: 20px!important;
    -webkit-border-radius: 20px!important;
    background-color: #00ffff!important;
}
.thumb-ctg {
    font-size: 11px;
    line-height: 13px;
    padding: 5px 13px;
    margin-top: 12px;
    margin-right: 14px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
}
.thumb-ctg_draft {
    background-color: #5B5A99;
    color: #fff;
}
.thumb-ctg_publish {
    background-color: #12A80F;
    color: #fff;
}
.gland-info {
    margin-bottom: 100px;
}
.thumb-claim_note {
    font-size: 14px;
    line-height: 17px;
}
.rank-load .ant-skeleton-header {
    padding: 0 10px!important;
}
.social-load .ant-skeleton-header {
    padding: 0!important;
}
.country-icon {
    height: 22px;
}
