.check-full.ant-checkbox-wrapper {
    margin-left: 0!important;
}
.check-full .ant-checkbox + span {
    width: 100%;
    padding: 0;
}
.check-full .ant-checkbox {
    display: none;
}

.ant-checkbox-wrapper-checked .icon-check {
    display: block;
}
.room-item:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(86,186,74,0) 0%, rgba(28,26,66,1) 100%);
    border-radius: 1.3rem;
}
.metahut-overlay:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(86,186,74,0) 0%, rgba(28,26,66,.8) 100%);
    border-radius: 1.3rem;
}