.overlay-card:after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: rgba(0,0,0, .5);
    border-radius: 0.75rem;
    -webkit-border-radius: 0.75rem;
}