.map-details {
    position: absolute;
    right: 0;
    top: 100px;
    background-color: rgba(28,26,66,.7);
    z-index: 1;
    padding: 15px 30px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    transition: right ease 1s;
}
.plot-square {
    width: 20px;
    min-width: 20px;
    height: 20px;
    object-fit: cover;
}
.plot-red {
    background-color: #D26D6D;
}
.plot-black {
    background-color: #3A3B59;
}
.plot-green {
    background-color: #00FF38;
}
.plot-yellow {
    background-color: #D6DB5A;
}
.plot-gray {
    background-color: #5E6077;
}
.plot-blue {
    background-color: #00FFFF;
}
.map-zoom {
    background-color: rgba(28,26,66,.5);
    padding: 12px 14px;
    border-radius: 5px;
}
.map-zoom button {
    background-color: transparent;
    border: none;
    cursor: pointer;
}
.map-zoom button:hover,
.map-zoom button.active {
    color: #00FFFF;
}
.map-zoom-dots:before {
    content: "";
    border: 1px dashed #fff;
    display: block;
    transform: translateX(-50%);
    height: 100%;
    position: absolute;
    top: 0;
    left: 50%;
    opacity: .4;
}
.map-zoom-dots button {
    width: 8px;
    min-width: 8px;
    height: 8px;
    object-fit: cover;
    background-color: #fff;
    border-radius: 30px;
    padding: 0;
    cursor: pointer;
    z-index: 1;
}
.map-zoom-dots button:hover,
.map-zoom-dots button.active {
    background-color: #00FFFF;
}
.map-details-caret{
    display: none!important;
}

@media (max-width: 992px) {
    .map-details-caret {
        display: block!important;
        position: absolute !important;
        margin-left: -62px;
        border-radius: 10px 0 0 10px !important;
        background-color: rgba(28, 26, 66, .7) !important;
        line-height: normal !important;
        padding-left: 12px !important;
        padding-right: 12px !important;
        z-index: 1!important;
    }

    .map-details-caret i {
        font-size: 22px !important;
    }

    .map-details-close {
        right: -245px;
    }
}