

.formContainer .formItem {
	margin-top: 20px;
}

/*.formContainer .formItem input {*/
/*	border: 1.5px solid grey;*/
/*	border-radius: 40px;*/
/*	height: 55px;*/
/*	width: 400px;*/
/*	background-color: #131d25;*/
/*	padding-left: 15px;*/
/*	color: #fff;*/
/*}*/

.formContainer .formItem h6 {
	padding: 5px 0 10px 15px;
	font-size: 16px;
	color: #fff;
}

/*.formContainer .formItem select {*/
/*	width: 400px;*/
/*	height: 55px;*/
/*	background-color: #131d25;*/
/*	border-radius: 40px;*/
/*	border: 1.5px solid grey;*/
/*	padding-left: 15px;*/
/*	color: grey;*/
/*}*/

.formContainer .fileChoose input {
	padding-top: 13px;
	color: grey;
}

.formContainer .fileChoose input::file-selector-button {
	display: none;
}

.formContainer .formItemColorPicker {
	display: flex;
	align-items: center;
	margin-top: 20px;
}

.formItemColorPicker h6 {
	color: #fff;
	font-size: 15px;
}

.formContainer .formItemColorPicker input {
	height: 40px;
	width: 40px;
	/*background-color: #131d25;*/
	color: #fff;
	margin-left: 20px;
}

.buttonContainer {
	justify-content: space-between;
	display: flex;
	align-items: center;
}

.buttonContainer h1{
	color: #fff;
}

.buttonContainer button {
	height: 50px;
	border-radius: 15px;
	background-color: var(--yellow-color);
	color: grey;
	display: flex;
	align-items: center;
	color: #000;
}

.buttonContainer h1 {
	width: 90%;
}

@keyframes spinner {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

.loading-spinner {
	width: 25px;
	height: 25px;
	border: 3px solid #f3f3f3;
	border-top: 3px solid #383636;
	border-radius: 50%;
	animation: spinner 1.5s linear infinite;
}

.load-data {
	width: 15px;
	height: 15px;
	border: 2px solid #9796cf;
	border-top: 2px solid #00ffff;
	border-radius: 50%;
	animation: spinner 1.5s linear infinite;
}

.validation-border {
	border: 1px solid red !important;
}