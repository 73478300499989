.search .ant-input {
    background-color: transparent;
}
.search .ant-input-prefix {
    margin-right: 12px;
}
.search.ant-input-affix-wrapper {
    padding: 4px 15px;
}
.search .ant-input {
    color: #fff!important;
}
.search .anticon svg {
    fill: #5B5A99;
}
.nav-menu a,
.nav-menu a:hover {
    color: #fff;
}
.nav-menu a.active {
    background-color: #b1afcd;
    color: #000;
}