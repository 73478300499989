
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
    position: relative!important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.gen-plot { 
  fill: #3d3d5b; 
  stroke: #222; 
} 

.gen-plot:hover { 
  fill: #b3b0cc; 
} 



.selected-plot {
	fill : #36fdff;
}

.buyed-plot {
	fill : #d26d6d;
}

.disable-plot {
	fill : #d8db58;
}


