.events-filter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    column-gap: 40px;
    row-gap: 20px;
    margin-bottom: 69px;
}
.events-action .input-search {
    padding: 0 15px;
}
.events-action,
.events-nav {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    column-gap: 40px;
    row-gap: 20px;
}
.events-nav a span {
    color: #B1AFCD;
    font-size: 18px;
    font-weight: 500;
}
.events-nav a.active span {
    color: #fff;
}
.events-submit {
    display: flex;
    align-items: center;
    column-gap: 10px;
}
.events-submit i {
    font-size: 24px;
    color: #B1AFCD;
}
.events-submit span {
    color: #B1AFCD;
    font-size: 18px;
    font-weight: 500;
}
.events-status {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 16px;
    margin-top: 18px;
}
.events-status .ant-btn {
    border-radius: 20px;
    -webkit-border-radius: 20px;
    padding: 7px 29px;
    font-size: 10px;
    font-weight: 700;
    line-height: normal;
    height: auto;
}
.events-status .draft-btn,
.events-status .draft-btn.ant-btn:active,
.events-status .draft-btn.ant-btn:focus {
    color: #fff;
    background: #5B5A99;
    border-color: transparent;
}
.events-status .draft-btn.ant-btn:hover {
    color: #5B5A99;
    background: transparent;
    border-color: #5B5A99;
}
.events-status .host-btn,
.events-status .host-btn.ant-btn:active,
.events-status .host-btn.ant-btn:focus {
    color: #fff;
    background: #AEACAC;
    border-color: transparent;
}
.events-status .host-btn.ant-btn:hover {
    color: #AEACAC;
    background: transparent;
    border-color: #AEACAC;
}
.events-status .public-btn,
.events-status .public-btn.ant-btn:active,
.events-status .public-btn.ant-btn:focus {
    color: #fff;
    background: #46B57F;
    border-color: transparent;
}
.events-status .public-btn.ant-btn:hover {
    color: #46B57F;
    background: transparent;
    border-color: #46B57F;
}
.events-status .finished-btn,
.events-status .finished-btn.ant-btn:active,
.events-status .finished-btn.ant-btn:focus {
    color: #fff;
    background: #E4751F;
    border-color: transparent;
}
.events-status .finished-btn.ant-btn:hover {
    color: #E4751F;
    background: transparent;
    border-color: #E4751F;
}
.events-status_note {
    color: #000;
    font-size: 12px;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 0;
}
.events-status_note span {
    font-weight: 700;
    color: #5B5A99;
}

.eslider {
    margin-bottom: 92px;
    overflow: hidden;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    min-height: 425px;
}
.eslider .slick-list {
    border-radius: 20px;
    -webkit-border-radius: 20px;
}
.eslider-control {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    column-gap: 14px;
    margin-bottom: 14px;
}
.eslider-left.ant-btn,
.eslider-right.ant-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
    -webkit-border-radius: 30px;
    padding: 4px;
    border: none;
    font-size: 15px;
    width: 36px;
    min-width: 36px;
    height: 36px;
}
.eslider-right.ant-btn {
    transform: rotate(180deg);
}
.eslider .slick-dots-bottom {
    bottom: 0!important;
    margin-bottom: 0;
}
.eslider .slick-dots li {
    margin: 0 15px!important;
}
.eslider .slick-dots li button {
    opacity: 1!important;
}
.eslider .slick-dots li.slick-active button {
    background-color: #00FFFF!important;
}
.eslider_item {
    padding: 26px;
    background-color: #fff;
}
.eslider_img {
    height: 100%;
    max-height: 340px;
    width: 100%;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    object-fit: cover;
}
.eslider_details {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding: 20px 36px 5px 33px;
}
.eslider_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    column-gap: 1rem;
    row-gap: 1rem;
    margin-bottom: 14px;
}
.eslider_date {
    font-size: 23px;
    color: #AEACAC;
    font-weight: 700;
}
.eslider-catg {
    width: 22px;
    min-width: 22px;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    background: #A27AF7;
    box-shadow: 0px -2px 2px 0px rgba(162, 122, 247, 0.40) inset, 0px 2px 2px 0px rgba(255, 255, 255, 0.40) inset, 0px 2px 4px 0px rgba(0, 0, 0, 0.20);
    backdrop-filter: blur(2.5px);
}
.eslider-catg i {
    color: #fff;
    font-size: 8px;
}
.eslider-price {
    display: flex;
    align-items: center;
    column-gap: 5px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    background-color: #e5fafa;
    color: #10B0C9;
    font-weight: 700;
    font-size: 12px;
    padding: 6px 12px;
}
.eslider-price img {
    width: 16px;
    height: 16px;
}
.eslider-title {
    font-size: 2rem;
    line-height: normal;
    margin-bottom: 14px;
    font-weight: 700;
    max-width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.eslider_user {
    display: flex;
    align-items: center;
    column-gap: 5px;
}
.eslider_user .user-box {
    width: 22px;
    min-width: 22px;
    height: 22px;
}
.eslider_username {
    font-size: 12px;
    color: #AEACAC;
    font-weight: 700;
    line-height: normal;
}
.eslider_metahut {
    display: flex;
    align-items: center;
    column-gap: 5px;
}
.eslider_metahut span,
.eslider_plot span {
    color: #AEACAC;
    font-size: 12px;
    font-weight: 700;
    line-height: normal;
}
.eslider_plot span span {
    display: none;
}
.eslider_plot {
    display: flex;
    align-items: center;
    column-gap: 10px;
}
.eslider_plot img {
    height: 22px;
}
.eslider_credit {
    display: flex;
    align-items: center;
    column-gap: 1rem;
    margin-bottom: 20px;
}
.eslider_credit > div {
    width: calc(100% / 3);
}
.eslider_desc {
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    color: #000;
    margin-bottom: 20px;
}
.eslider-visitor {
    display: flex;
    position: relative;
}
.eslider-visitor .user-box {
    width: 37px;
    min-width: 37px;
    height: 37px;
}
.eslider-visitor > div + div {
    z-index: 1;
    margin-left: -12px;
}
.eslider-visitor_count {
    width: 37px;
    min-width: 37px;
    height: 37px;
    padding: 5px;
    background-color: #EEEBEB;
    border-radius: 37px;
    -webkit-border-radius: 37px;
    color: #5B5A99;
    font-weight: 700;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.eslider-btn {
    font-size: 12px!important;
    font-weight: 700!important;
    color: #fff!important;
    text-transform: uppercase;
    border-radius: 5px!important;
    background-color: #FF409A!important;
    padding: 9px 24px!important;
    border: none!important;
    height: auto!important;
}
.eslider-btn.btn-disable {
    background-color: #1c1a42!important;
}
.eslider_action {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 1rem;
    row-gap: 1rem;
    flex-wrap: wrap;
    margin-bottom: 30px;
}
.eslider-bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 1rem;
    row-gap: 1rem;
    flex-wrap: wrap;
}
.eslider-pool {
    display: flex;
    align-items: center;
    column-gap: 10px;
}
.eslider-reward,
.eslider-amount {
    display: flex;
    align-items: center;
    column-gap: 5px;
}
.eslider-reward i {
    font-size: 14px;
    color: #DC6999;
    background-color: #FCD9C0;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    padding: 5px 6px;
}
.eslider-reward span,
.eslider-share span {
    color: #AEACAC;
    font-size: 14px;
    font-weight: 700;
}
.eslider-share {
    display: flex;
    align-items: center;
    column-gap: 5px;
}
.eslider-share i {
    font-size: 14px;
    color: #AEACAC;
    background-color: #EFECEC;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    padding: 5px 6px;
}
.eslider-amount img {
    height: 20px;
}
.eslider-amount span {
    font-size: 14px;
    font-weight: 700;
    color: #5B5A99;
}
.events-layout {
    margin-top: 92px;
    column-gap: 2rem;
}
.events-ctg,
.events-ctg_nav,
.events-layout {
    background: #27244b!important;
}
.events-ctg-ttl {
    color: #9796CF;
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 37px;
}
.events-ctg_nav li button {
    font-size: 1rem;
    color: #B1AFCD!important;
    font-weight: 500;
    display: flex;
    align-items: center;
    padding: 0 5px 0 18px!important;
    background: transparent;
    border: none;
    box-shadow: none;
    border-radius: 20px;
    -webkit-border-radius: 20px;
}
.events-ctg_nav li button:hover {
    background: transparent;
}
.events-ctg_nav li button i {
    margin-right: 8px;
}
.events-ctg_count  {
    display: flex!important;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: 500;
    width: 26px;
    min-width: 26px;
    height: 23px;
    border-radius: 26px;
    -webkit-border-radius: 26px;
    background-color: #3F3C77;
    margin-left: 12px;
}
.events-ctg_nav li {
    height: 33px!important;
    line-height: 33px!important;
    padding: 0!important;
    margin-bottom: 10px!important;
}
.events-ctg_nav li.ant-menu-item-selected {
    background-color: transparent!important;
}
.events-ctg_nav li.ant-menu-item-selected button {
    width: fit-content;
    background-color: #5B5A99;
    color: #fff!important;
}
/*.events-list,*/
/*.events-ctg {*/
/*    height: 800px;*/
/*    overflow-y: auto;*/
/*    overflow-x: hidden;*/
/*}*/
.events-card {
    padding: 12px;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    background-color: #fff;
}
.events-list .ant-row {
    row-gap: 30px;
}
.events-cover {
    position: relative;
    width: 100%;
    min-width: 100%;
    height: 140px;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    overflow: hidden;
    margin-bottom: 20px;
    padding: 13px 16px;
}
.events-badge {
    border-radius: 10px;
    -webkit-border-radius: 10px;
    background-color: #EF5261;
    color: #fff;
    padding: 2px 8px;
    display: inline-block;
}
.events-card .events_details {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.events-card .events_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    column-gap: 1rem;
    row-gap: 1rem;
    margin-bottom: 16px;
}
.events-card .events_date {
    font-size: 11px;
    color: #AEACAC;
    font-weight: 700;
}
.events-card .events-price {
    display: flex;
    align-items: center;
    column-gap: 5px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    font-weight: 700;
    font-size: 10px;
    padding: 4px 8px;
}
.events-card .events-price_soto {
    background-color: #e5fafa;
    color: #10B0C9;
}
.events-card .events-price_meto {
    background-color: #F0D0FA;
    color: #9E18C7;
}
.events-card .events-price img {
    width: 12px;
    height: 12px;
}
.events-card .events-title {
    font-size: 16px;
    line-height: normal;
    margin-bottom: 6px;
    font-weight: 700;
    max-width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.events-author {
    color: #AEACAC;
    font-size: 10px;
    font-weight: 700;
    margin-bottom: 22px;
}
.events-card .events_username {
    font-size: 12px;
    color: #AEACAC;
    font-weight: 700;
    line-height: normal;
}
.events-card .events-visitor {
    display: flex;
    position: relative;
}
.events-card .events-visitor .user-box {
    width: 34px;
    min-width: 34px;
    height: 34px;
}
.events-card .events-visitor > div + div {
    z-index: 1;
    margin-left: -12px;
}
.events-card .events-visitor_count {
    width: 34px;
    min-width: 34px;
    height: 34px;
    padding: 5px;
    background-color: #EEEBEB;
    border-radius: 34px;
    -webkit-border-radius: 34px;
    color: #5B5A99;
    font-weight: 700;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.events-card .events-btn {
    font-size: 9px!important;
    font-weight: 700!important;
    color: #fff!important;
    text-transform: uppercase;
    border-radius: 5px!important;
    background-color: #FF409A!important;
    padding: 7px 12px!important;
    border: none!important;
    height: auto!important;
}
.events-btn.btn-disable {
    background-color: #1c1a42!important;
}
.events-card .events_action {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 1rem;
    row-gap: 1rem;
    flex-wrap: wrap;
    margin-bottom: 26px;
}
.events-card .events-bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 1rem;
    row-gap: 1rem;
    flex-wrap: wrap;
}
.events-card .events-pool {
    display: flex;
    align-items: center;
    column-gap: 10px;
}
.events-card .events-reward,
.events-card .events-amount {
    display: flex;
    align-items: center;
    column-gap: 5px;
}
.events-card .events-reward i {
    font-size: 12px;
    color: #DC6999;
    background-color: #FCD9C0;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    padding: 5px 6px;
}
.events-card .events-reward span {
    color: #AEACAC;
    font-size: 12px;
    font-weight: 700;
}
.events-card .events-share {
    display: flex;
    align-items: center;
    column-gap: 5px;
}
.events-card .events-share i {
    font-size: 12px;
    color: #AEACAC;
    background-color: #EFECEC;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    padding: 5px 6px;
}
.events-card .events-amount img {
    height: 16px;
}
.events-card .events-amount span {
    font-size: 11px;
    font-weight: 700;
    color: #5B5A99;
}
.events-sider .vert_menu {
    row-gap: 20px;
    background: transparent!important;
}
.events-sider .vert_menu:before,
.events-sider .vert_menu:after {
    content: none;
}
.events-sider .vert_menu li {
    position: relative;
    padding: 0 0 0 4px!important;
    margin: 0!important;
}
.events-sider .vert_menu li:after {
    content: none;
}
.events-sider .vert_menu li:before {
    content: '.';
    font-size: 14px;
    color: #B1AFCD;
    position: absolute;
    left: 0;
    font-size: 30px;
    margin-top: -18px;
}
.events-sider .vert_menu li.ant-menu-item-selected {
    background-color: transparent!important;
}
.events-sider .vert_menu li.ant-menu-item-selected:before {
    color: #fff;
    font-size: 40px;
    margin-top: -24px;
}
.events-sider .vert_menu li button {
    background: transparent;
    border: none;
    box-shadow: none;
    font-weight: 400;
    color: #B1AFCD;
    font-size: 20px;
}
.events-sider .vert_menu li button:after {
    content: none;
}
.events-sider .vert_menu li.ant-menu-item-selected button {
    color: #fff;
    font-weight: 700;
}
.events-ttl {
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 35px;
}
.back-nav {
    display: flex;
    align-items: center;
    column-gap: 10px;
}
.back-nav span {
    color: #9796CF;
    font-size: 16px;
    font-weight: 500;
}
.back-nav i {
    color: #9796CF;
    font-size: 12px;
}
.eform-header {
    margin-bottom: 46px;
}
.eform-act {
    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    column-gap: 1rem;
    row-gap: 1rem;
}
.eform-content {
    margin-top: 54px;
}
.eform-act_info {
    width: 60%;
}
.eform-act_ttl {
    color: #FFF;
    font-size: 18px;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 5px;
}
.eform-act_desc {
    color: #FFF;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 0;
}
.eform-act_btn {
    display: flex;
    align-items: center;
    column-gap: 27px;
}
.save-btn.ant-btn,
.submit-btn.ant-btn {
    border-radius: 20px;
    -webkit-border-radius: 20px;
    padding: 12px 27px;
    font-size: 16px;
    font-weight: 700;
    line-height: normal;
    height: auto;
    min-width: 116px;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 6px;
}
.save-btn.ant-btn,
.save-btn.ant-btn:active,
.save-btn.ant-btn:focus {
    color: #fff;
    background: #9796CF;
    border-color: transparent;
}
.save-btn.ant-btn:hover {
    color: #9796CF;
    background: transparent;
    border-color: #9796CF;
}
.submit-btn.ant-btn,
.submit-btn.ant-btn:active,
.submit-btn.ant-btn:focus {
    color: #000;
    background: #0FF;
    border-color: transparent;
}
.submit-btn.ant-btn:hover {
    color: #0FF;
    background: transparent;
    border-color: #0FF;
}
.eform-content_ttl {
    color: #BCBAF9;
    font-size: 20px;
    font-weight: 400;
    line-height: normal;
}
.eform-content_desc {
    color: #FFF;
    font-size: 14px;
    font-weight: 300;
    line-height: normal;
    margin-bottom: 0;
}
.eform-content_body {
    margin-top: 78px;
    padding-bottom: 120px;
}
.elabel {
    display: block;
    color: #FFF;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 9px;
}
.eform-item {
    margin-bottom: 52px;
}
.einput-limit {
    color: #FFF;
    font-size: 12px;
    font-weight: 400;
    line-height: normal;
    text-align: right;
    margin-top: 8px;
}
.einput-txt {
    font-size: 12px;
    font-weight: 400;
    line-height: normal;
    margin-top: 16px;
}
.eform-input,
.eform-textarea {
    border-radius: 5px;
    background: #5B5A99;
    border: none;
    width: 100%!important;
    color: #fff;
}
.eform-textarea {
    resize: none;
}
.eform-upload.ant-btn,
.eform-upload.ant-btn:active,
.eform-upload.ant-btn:focus {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 10px;
    border-radius: 5px;
    background: #1C1A42;
    border: none;
    padding: 26px;
    width: 100%;
    color: #FFF;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 500;
    line-height: normal;
    min-height: 72px;
}
.eform-upload.ant-btn:hover {
    background: #19183c;
    color: #FFF;
}
.eform-file {
    display: none;
}
.eform-file_val {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #B1AFCD;
    font-size: 11px;
    font-family: Roboto;
    font-weight: 400;
    line-height: normal;
    margin-top: 10px;
}
.eform-file_val .line-y {
    height: 14px;
    margin: 0 5px;
}
.eform-file_val span {
    color: #50E3C2;
}
.eform-cover {
    border-radius: 5px;
    overflow: hidden;
    position: relative;
}
.eform-reset.ant-btn,
.eform-reset.ant-btn:active,
.eform-reset.ant-btn:focus {
    position: absolute;
    right: 50%;
    top: 50%;
    z-index: 1;
    padding: 4px 8px;
    background: #0FF;
    color: #000;
    border-color: transparent;
    display: none;
}
.eform-reset.ant-btn:hover {
    background: #04cfcf;
    color: #000;
}
.eform-cover:hover .eform-reset.ant-btn {
    display: block;
}
.eform-cover:hover:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    background: #000;
    opacity: .6;
    width: 100%;
    height: 100%;
}
.eform-picker.ant-picker {
    height: 46px;
    border-color: transparent;
    border-radius: 5px;
    background: #5B5A99;
}
.eform-picker.ant-picker:hover {
    border-color: transparent;
}
.eform-picker .ant-picker-input > input,
.eform-picker .ant-picker-suffix {
    color: #fff;
}
.eform-picker.ant-picker-focused {
    box-shadow: none;
}
.eform-utc {
    width: 30%;
    color: #9796CF;
    font-size: 13px;
    font-weight: 500;
    line-height: normal;
    font-family: "Roboto";
}
.eform-group {
    margin-top: 64px;
    margin-bottom: 86px;
}
.eform-group > .ant-row {
    margin-top: 25px;
    row-gap: 2rem;
}
.eform-select.ant-select {
    width: 100%;
}
.eform-select.ant-select .ant-select-selector {
    border-radius: 5px!important;
    background-color: #5B5A99!important;
    border-color: transparent!important;
    height: auto!important;
    box-shadow: none!important;
    display: flex;
    align-items: center;
    min-height: 46px;
}
.eform-select .ant-select-selection-item {
    background-color: #3F3C77!important;
    border: 1px solid #9796CF!important;
    border-radius: 10px!important;
}
.eform-select .ant-select-selection-item .anticon-close {
    color: #1c1a42;
    margin-top: 3px;
    margin-left: 3px;
    margin-right: 3px;
}
.eform-select .ant-select-selection-item i {
    margin-right: 5px;
}
.eform-select.ant-select,
.eform-select.ant-select-single.ant-select-open .ant-select-selection-item,
.eform-select .ant-select-arrow{
    color: #fff;
}
.eform-select .ant-select-item-option-content i {
    margin-right: 5px;
    width: 18px;
    display: inline-block;
}
.eform-select .ant-select-item {
    color: #000;
}
.evenue-opt_item {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    row-gap: 15px;
    padding: 50px;
    border-radius: 15px;
    background: #1C1A42;
    min-height: 230px;
}
.evenue-opt_icon {
    width: 48px;
}
.evenue-opt_ttl {
    color: #FFF;
    font-size: 16px;
    font-weight: 300;
    line-height: normal;
    margin-bottom: 0;
    text-align: center;
}
.evenue-opt_item span {
    color: #9796CF;
    font-size: 11px;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
}
.emodal .icon-close {
    color: rgba(255, 255, 255, .7);
    font-size: 20px;
    transition: color .3s ease-in-out;
}
.emodal .icon-close:hover {
    color: rgba(255, 255, 255, 1);
}
.emodal .ant-modal-close-x {
    display: flex;
    align-items: center;
    justify-content: center;
}
.emodal .ant-modal-content {
    border-radius: 20px!important;
    border: 2px solid #9796CF;
    background-color: #272448!important;
}
.emodal.ant-modal {
    width: 1000px!important;
    font-family: "Montserrat";
}
.emodal .ant-modal-body {
    padding: 30px 60px;
}
.emodal-ttl {
    color: #FFF;
    font-size: 18px;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 0;
}
.emodal-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    column-gap: 1rem;
    row-gap: 1rem;
}
.emodal-list {
    margin-top: 60px;
}
.emodal-list .ant-row {
    row-gap: 40px;
}
.emodal-list_sbj {
    color: #FFF;
    font-size: 18px;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 40px;
}
.emodal .line-x {
    margin: 20px 0;
}
.emodal-list_empty {
    color: #fff;
    font-size: 15px;
    background-color: #1C1A42;
    padding: 18px 20px;
    border-radius: 15px;
}
.emodal-list .ant-radio-button-wrapper {
    height: auto;
    background: transparent;
    border-color: transparent!important;
    box-shadow: none!important;
    padding: 0;
    width: 100%;
}
.emodal-list .ant-radio-button-wrapper-checked {
    background: transparent!important;
}
.emodal-list .ant-radio-button-wrapper .thumb {
    border: 3px solid transparent;
}
.emodal-list .ant-radio-button-wrapper-checked .thumb {
    border: 3px solid #0FF;
}
.modal-full.ant-modal {
    width: 100% !important;
    max-width: 100% !important;
    height: 100dvh;
}
.modal-full .ant-modal-content {
    border-radius: 0 !important;
    height: 100%;
}
.modal-full .ant-modal-body {
    height: 100%;
    color: #fff;
}
.addmodal.ant-modal {
    width: 450px !important;
}
.addmodal.ant-modal svg {
    fill: #fff;
    height: 3rem;
    width: 3rem;
}
.addmodal.ant-modal .bg-cyan-400 {
    border-radius: 4px;
    background-color: transparent;
    border: 1px solid #00ccff;
}
.addmodal.ant-modal .text-sm {
    color: #fff !important;
    flex-direction: column;
    display: flex;
    align-items: flex-start;
}
.addmodal.ant-modal button {
    color: #fff !important;
}
.addmodal.ant-modal .jsx-parser {
    display: flex;
    flex-direction: column;
}
.addmodal.ant-modal .bg-cyan-400 > div {
    justify-content: flex-start;
}
.addmodal.ant-modal a {
    color: #fff;
}
.ereward-nav {
    background: #27244b!important;
    column-gap: 20px;
    border: none!important;
    flex-wrap: wrap;
    margin-bottom: 50px;
}
.ereward-nav.ant-menu:before,
.ereward-nav.ant-menu:after {
    content: none;
}
.ereward-nav li button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 8px;
    padding: 10px 16px!important;
    background: #1C1A42;
    border: 4px solid transparent;
    box-shadow: none;
    border-radius: 15px;
    -webkit-border-radius: 15px;
    height: 100%;
    width: 100%;
}
.ereward-nav li button:focus,
.ereward-nav li button:active {
    background: #1C1A42;
    border: 4px solid transparent;
}
.ereward-nav li button h3 {
    color: #FFF;
    font-size: 13px;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 0;
}
.ereward-nav li button span {
    color: #9796CF;
    font-size: 11px;
    font-weight: 700;
    line-height: normal;
}
.ereward-nav li button:hover {
    border: 4px solid #0FF;
    background: transparent;
}
.ereward-nav li.ant-menu-item-disabled button {
    cursor: not-allowed;
    opacity: .5;
}
.ereward-nav li.ant-menu-item-disabled button:hover {
    border: 4px solid transparent;
    background: #1C1A42;
}
.ereward-nav li button img {
    height: 32px;
}
.ereward-nav li {
    height: 112px!important;
    width: calc((100%/6) - 17px)!important;
    line-height: 112px!important;
    padding: 0!important;
    margin-bottom: 20px!important;
    position: relative!important;
    opacity: 1!important;
}
.ereward-nav li:hover:after {
    content: none;
}
.ereward-nav li.ant-menu-item-selected {
    background-color: transparent!important;
}
.ereward-nav li.ant-menu-item-selected button {
    background-color: #5B5A99;
    color: #fff!important;
    border: 4px solid #0FF;
}
.ereward-nav li.ant-menu-item-selected:after {
    content: none;
}
.ereward-nav li.ant-menu-submenu {
    display: none;
}
.ereward-amount {
    margin-top: 50px;
}
.ereward-amount .eform-input {
    max-width: 227px;
}
.ereward-amount .einput {
    display: flex;
    align-items: center;
    column-gap: 15px;
}
.einput-suffix {
    display: flex;
    align-items: center;
    column-gap: 4px;
    color: #FFF;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
}
.einput-suffix img {
    height: 24px;
}
.ereward-body {
    padding-bottom: 80px;
}
.eticket-head {
    margin-top: 100px;
}
.ehosts-add.ant-btn {
    border-radius: 20px;
    background: #FF409A;
    color: #fff;
    font-weight: 700;
    line-height: normal;
    font-size: 16px;
    height: 44px;
    min-width: 134px;
    border-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
}
.ehosts-form {
    display: flex;
    align-items: center;
    column-gap: 40px;
}
.ehosts-search {
    width: 60%;
}
.ehosts-search .input-search {
    width: 100%;
}
.ehosts-card {
    padding: 24px;
    border-radius: 15px;
    background: #1C1A42;
    text-align: center;
    min-height: 260px;
}
.ehosts-status {
    color: #9796CF;
    font-size: 18px;
    font-weight: 700;
    line-height: normal;
    display: block;
    text-align: center;
}
.ehosts-fname {
    font-weight: 500;
    line-height: normal;
    color: #FFF;
    font-size: 14px;
    margin-bottom: 6px;
}
.ehosts-card .user-box {
    width: 60px;
    min-width: 60px;
    height: 60px;
    margin: 18px auto;
}
.ehosts-username {
    font-weight: 300;
    line-height: normal;
    color: #FFF;
    font-size: 14px;
    display: block;
    text-align: center;
}
.ehosts-remove.ant-btn {
    margin-top: 20px;
    border-radius: 20px;
    background: #B1AFCD;
    color: #1C1A42;
    font-weight: 700;
    line-height: normal;
    font-size: 12px;
}
.ehosts-list {
    margin-top: 57px;
}
.ehosts-list .ant-row {
    row-gap: 30px;
}
.ehosts-body {
    padding-bottom: 93px;
}
.ehosts-head {
    margin-top: 54px;
}

.einfo_user {
    display: flex;
    align-items: center;
    column-gap: 5px;
}
.einfo_user .user-box {
    width: 22px;
    min-width: 22px;
    height: 22px;
}
.einfo_username {
    font-size: 12px;
    color: #AEACAC;
    font-weight: 700;
    line-height: normal;
}
.einfo_network {
    display: flex;
    align-items: center;
    column-gap: 10px;
    row-gap: 10px;
    flex-wrap: wrap;
}
.einfo_metahut {
    display: flex;
    align-items: center;
    column-gap: 5px;
}
.einfo_metahut img {
    height: 18px;
}
.einfo_metahut span {
    color: #AEACAC;
    font-size: 12px;
    font-weight: 700;
    line-height: normal;
}
.einfo-meta {
    display: flex;
    flex-direction: column;
    row-gap: 1.25rem;
    margin-bottom: 60px;
}
.einfo-meta_item {
    display: flex;
    align-items: center;
    column-gap: 10px;
    color: #AEACAC;
    font-size: 16px;
    font-weight: 700;
    line-height: normal;
}
.einfo-ttl {
    color: #FFF;
    font-size: 42px;
    font-weight: 700;
    line-height: normal;
}
.einfo-meta_item a {
    color: #0CF;
    font-size: 16px;
    font-weight: 700;
    line-height: normal;
}
.einfo-cover {
    border-radius: 20px;
    overflow: hidden;
}
.einfo-cover img {
    width: 100%;
}
.einfo-datetime {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 80px;
}
.einfo-date {
    width: calc(100%/2 - 40px);
}
.einfo-date_label {
    color: #FFF;
    font-weight: 700;
    line-height: normal;
    font-size: 20px;
    text-transform: uppercase;
}
.einfo-date_set {
    color: #BCBAF9;
    font-weight: 600;
    line-height: normal;
    font-size: 17px;
}
.einfo-desc {
    margin-top: 50px;
    color: #fff;
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    margin-bottom: 0;
}
.einfo-visitor {
    display: flex;
    position: relative;
}
.einfo-visitor .user-box {
    width: 64px;
    min-width: 64px;
    height: 64px;
}
.einfo-visitor > div + div {
    z-index: 1;
    margin-left: -12px;
}
.einfo-visitor_count {
    width: 64px;
    min-width: 64px;
    height: 64px;
    padding: 5px;
    background-color: #EEEBEB;
    border-radius: 64px;
    -webkit-border-radius: 64px;
    color: #5B5A99;
    font-weight: 700;
    font-size: 23px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.einfo-btn {
    font-size: 18px!important;
    font-weight: 700!important;
    color: #fff!important;
    text-transform: uppercase;
    border-radius: 5px!important;
    background-color: #FF409A!important;
    padding: 11px 29px!important;
    border: none!important;
    height: auto!important;
}
.einfo-btn.btn-disable {
    background-color: #1c1a42!important;
}
.einfo_action {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 1rem;
    row-gap: 1rem;
    flex-wrap: wrap;
    margin-bottom: 100px;
    margin-top: 60px;
}
.etrending {
    margin-top: 100px;
}
.etrending-ttl {
    color: #fff;
    font-size: 22px;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 50px;
}
.etrending .ant-row {
    row-gap: 30px;
}
.esingle {
    padding-bottom: 9.25rem;
}
.edets-card {
    background: #1C1A42;
    border-radius: 15px;
    border: 2px solid rgba(91, 90, 153, 0.50);
    padding: 20px 20px 45px;
    margin-bottom: 40px;
}
.edets_ttl {
    color: #9796CF;
    font-size: 14px;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    margin-bottom: 0;
}
.edets-card_body {
    display: flex;
    flex-direction: column;
    row-gap: 25px;
    align-items: center;
    margin-top: 30px;
}
.edets-token {
    display: flex;
    align-items: center;
    column-gap: 10px;
}
.edets-token img {
    height: 26px;
}
.edets-token_amount {
    color: #fff;
    font-size: 23px;
    font-weight: 500;
    line-height: normal;
}
.edets-token_free {
    color: #fff;
    font-size: 22px;
    font-weight: 700;
    line-height: normal;
}
.edets-desc {
    color: #fff;
    font-size: 12px;
    font-weight: 400;
    line-height: normal;
    max-width: 247px;
    text-align: center;
}
.edets-card .submit-btn.ant-btn {
    width: fit-content;
}
.edets-note {
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 20px;
}
.evisit {
    color: #00FFFF;
    font-weight: bold;
    font-size: 24px;
    font-weight: 700;
    line-height: normal;
}
.eremain {
    text-align: center;
    margin-top: 20px;
}
.eremain-txt {
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 0;
}
.eremain-status {
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 0;
    padding: 4px 12px;
    border-radius: 10px;
    font-size: 14px;
    font-weight: 500;
}
.eremain-live {
    background-color: red;
}
.eremain-finished {
    background-color: green;
}
.countdown {
    color: #fff;
    margin: 0 auto 20px;
    font-size: 24px;
    font-weight: 700;
    line-height: normal;
    display: flex;
    justify-content: space-around;
    width: 80%;
}
.countdown span {
    font-size: 11px;
    font-weight: 400;
    color: #c7c6ff;
}

.ticket {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 30px;
    padding: 34px 26px;
    position: relative;
}
.ticket:after,
.ticket:before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    width: 100%;
    height: 10px;
    background-image: url("https://dcdn.metafluence.com/images/triangle.svg");
    background-position: center center;
}
.ticket:before {
    top: -9px;
}
.ticket:after {
    bottom: -9px;
    transform: rotate(-180deg);
}
.ticket-article {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 15px;
}
.ticket-subject {
    font-size: 12px;
    font-weight: 700;
    line-height: normal;
    color: #AEACAC;
    text-transform: uppercase;
}
.ticket-info {
    font-size: 12px;
    font-weight: 700;
    line-height: normal;
    color: #1C1A42;
}

/*Media*/
@media (max-width: 1199px) {
    .ereward-nav li {
        width: calc((100%/4) - 17px)!important;
    }
}
@media (max-width: 992px) {
    .ereward-nav li {
        width: calc((100%/3) - 17px)!important;
    }
}
@media (max-width: 425px) {
    .ereward-nav li {
        width: calc((100%/2) - 17px)!important;
    }
}