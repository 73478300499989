.select-metahut .ant-select-selector {
    background-color: #5B5A99!important;
    border: none!important;
    color: #fff;
    height: 25px!important;
    border-radius: 10px!important;
    -webkit-border-radius: 10px!important;
}
.select-payment .ant-select-selector {
    height: 30px!important;
}
.select-metahut .ant-select-arrow {
    color: #1C1A42;
}
.select-metahut {
    width: auto!important;
}